import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

import { scrollToRef } from '../../../helpers';

export const ToTopButton = (): ReactElement => {
  return (
    <div className="sticky z-10 bottom-2 w-full text-right max-w-md sm:max-w-5xl mx-auto px-2">
      <button
        onClick={() => scrollToRef('header')}
        className="btn btn--secondary btn--round opacity-90"
      >
        <FontAwesomeIcon icon={faArrowUp as any} />
      </button>
    </div>
  );
};
