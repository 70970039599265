import { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import './index.css';

import dognoimage from '../../../images/dognoimage.png';
import { Slide } from '../../../types/commonTypes';
import Loading from '../../alerts/Loading';

SwiperCore.use([Navigation, Autoplay]);

interface CarouselSwiperProps {
  slides: Slide[];
  loading?: boolean;
  styles?: string;
}

export const CarouselSwiper = (props: CarouselSwiperProps): ReactElement => {
  const { slides, loading, styles } = props;

  const renderSlides = () => {
    if (slides) {
      return slides
        .filter((slide) => slide.src !== '')
        .map((slide, i) => {
          // create slide text element if text is provided
          const slideText = slide.text ? (
            <div className="absolute bg-red-800 bottom-0 left-6 right-6 h-9 rounded-t-lg flex items-center justify-center">
              <p className="text-lg text-gray-100">{slide.text}</p>
            </div>
          ) : (
            <></>
          );
          // create the slide image
          const slideImage = (
            <>
              <img
                className="h-full w-full object-cover"
                src={slide.src}
                alt="carousel"
                onError={(e) => {
                  (e.target as HTMLImageElement).src = dognoimage;
                }}
              />
              {slideText}
            </>
          );
          // wrap the slide image in anchor tag if link is provided
          const slideElement = slide.link ? (
            <a href={slide.link} rel="noreferrer" target="_blank">
              {slideImage}
            </a>
          ) : (
            slideImage
          );

          return (
            <SwiperSlide key={i}>
              <div className="relative h-full w-full">{slideElement}</div>
            </SwiperSlide>
          );
        });
    } else {
      return <></>;
    }
  };

  return (
    <div className={styles}>
      <div className="w-full h-96 bg-gray-500 sm:h-48 md:h-64 rounded-xl lg:h-80 overflow-hidden shadow-md">
        {/* Loading spinner */}
        {loading && (
          <div className="w-full h-full flex items-center">
            <Loading />
          </div>
        )}
        {/* Large screen view */}
        <div className="hidden sm:inline">
          <Swiper
            className="rounded-xl w-full h-full"
            slidesPerView={3}
            navigation={true}
            loop={true}
            autoplay={{ delay: 2500 }}
            speed={500}
          >
            {renderSlides()}
          </Swiper>
        </div>
        {/* Small screen view */}
        <div className="sm:hidden inline">
          <Swiper
            className="rounded-xl w-full h-full"
            slidesPerView={1}
            navigation={true}
            loop={true}
            autoplay={{ delay: 2500 }}
            speed={500}
          >
            {renderSlides()}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
