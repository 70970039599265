import { ReactElement } from 'react';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import { EVENT_IS_RUNNING } from '../../constants';
import { Box } from '../../components/layout/Box/Box';

const style = {
  h2: 'text-2xl mb-2 font-semibold',
  boxDiv: 'pt-4',
};

export const Contact = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Contact Us</h1>
        {(EVENT_IS_RUNNING.DOGFEST_EVENT ||
          EVENT_IS_RUNNING.DOGFEST_SAVE) && (
          <Box>
            <p className='text-lg'>
              For questions regarding DogtoberFest, call us at (360) 460-6258 or
              email us at djbdfest@gmail.com
            </p>
          </Box>
        )}
        <div>
          <h2 className={style.h2}>Mail:</h2>
          <address className="not-italic">
            P.O. BOX 3966, Sequim, WA 98382
          </address>
        </div>
        <div className={style.boxDiv}>
          <h2 className={style.h2}>
            Facility Address (open by appointment only):
          </h2>
          <address className="not-italic">
            751 McComb RD, Sequim, WA 98382
          </address>
        </div>
        <div className={style.boxDiv}>
          <h2 className={style.h2}>Donations:</h2>
          <p>Can be dropped off in donation box outside gate</p>
        </div>
        <div className={style.boxDiv}>
          <h2 className={style.h2}>Phone:</h2>
          <p>(360) 460-6258</p>
        </div>
        <div className={style.boxDiv}>
          <h2 className={style.h2}>Email:</h2>
          <a href="mailto:wagranch@yahoo.com" className="anchor-dark">
            wagranch@yahoo.com
          </a>
        </div>
        <div className={style.boxDiv}>
          <h2 className={style.h2}>Tax ID #:</h2>
          <p>91-2132797</p>
        </div>
      </Section>
    </Page>
  );
};
