import * as yup from 'yup';
import { validPhoneRegex } from '../helpers';

const yupTypes: any = {
  shortAnswer: yup.string().max(64, 'Must be 1 - 64 characters.'),
  medAnswer: yup.string().max(500, 'Must be 1 - 500 characters.'),
  longAnswer: yup.string().max(2000, 'Must be 1 - 2000 characters.'),
  phone: yup
    .string()
    .matches(
      validPhoneRegex,
      'Must be valid phone number, e.g. (123) 456-7890'
    ),
  email: yup
    .string()
    .email('Must be a valid email address.')
    .max(64, 'Must be 1 - 64 characters.'),
  zip: yup
    .string()
    .max(20, 'Must be 1 - 20 characters.')
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid ZIP code.'),
  string: yup.string().required('Required.'),
};

export default yupTypes;
