import { gql } from '@apollo/client';

export const SEND_FORM_DOGTOBERFEST = gql`
  mutation SendFormDogtoberfest(
    $fullName: String!
    $email: String
    $phone: String!
    $tickets: Int!
    $tables: Int!
    $ticketName1: String
    $ticketName2: String
    $ticketName3: String
    $ticketName4: String
    $ticketName5: String
    $ticketName6: String
    $ticketName7: String
    $ticketName8: String
    $tableName1: String
    $tableName2: String
    $tableName3: String
    $tableName4: String
    $tableName5: String
    $tableName6: String
    $tableName7: String
    $tableName8: String
    $winePinotGrigio: Int!
    $wineMerlot: Int!
    $donation: Int!
    $total: Int!
  ) {
    sendFormDogtoberfest(
      fullName: $fullName
      email: $email
      phone: $phone
      tickets: $tickets
      tables: $tables
      ticketName1: $ticketName1
      ticketName2: $ticketName2
      ticketName3: $ticketName3
      ticketName4: $ticketName4
      ticketName5: $ticketName5
      ticketName6: $ticketName6
      ticketName7: $ticketName7
      ticketName8: $ticketName8
      tableName1: $tableName1
      tableName2: $tableName2
      tableName3: $tableName3
      tableName4: $tableName4
      tableName5: $tableName5
      tableName6: $tableName6
      tableName7: $tableName7
      tableName8: $tableName8
      winePinotGrigio: $winePinotGrigio
      wineMerlot: $wineMerlot
      donation: $donation
      total: $total
    ) {
      status
      orderNumber
    }
  }
`;

export const SEND_FORM_VOLUNTEER = gql`
  mutation SendFormVolunteer(
    $fullName: String!
    $email: String
    $phone: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $moreInfo: String
    $experienceDogs: String!
    $experienceOther: String
  ) {
    sendFormVolunteer(
      fullName: $fullName
      email: $email
      phone: $phone
      address: $address
      city: $city
      state: $state
      zip: $zip
      moreInfo: $moreInfo
      experienceDogs: $experienceDogs
      experienceOther: $experienceOther
    ) {
      status
      orderNumber
    }
  }
`;

export const SEND_FORM_FOSTER = gql`
  mutation SendFormFoster(
    $fullName: String!
    $email: String
    $phone: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $residenceType: String!
    $rentOrOwn: String!
    $adults: String!
    $familiarWithDogs: String!
    $allergiesToDogs: String!
    $numPets: String!
    $expectations: String!
    $experience: String!
    $lookingToAdopt: String!
    $petsDied: String!
    $explainPetsDied: String
    $hoursLeftAlone: String!
    $primaryCaretaker: String!
    $careAssistance: String!
    $secondaryCaretaker: String
    $petsGetAlong: String!
    $whereKeptDay: String!
    $whereKeptNight: String!
    $hasFence: String!
    $describeConfinement: String
    $hasIndoorConfinement: String!
    $describeIndoorConfinement: String
    $hasOutdoorShelter: String!
    $describeOutdoorShelter: String
    $hasIsolationArea: String!
    $describeIsolationArea: String
    $canAcceptStray: String!
    $describeFeeding: String!
    $typeOfFood: String!
    $hasConsideredNegatives: String!
    $canCareForSick: String!
    $canGiveMedicine: String!
    $canCareFixed: String!
    $injuryIsUpsetting: String!
    $canTransport: String!
    $preferredGender: String!
    $preferredSize: String!
    $preferredAge: String!
    $lengthOfCare: String!
    $canAcceptLitter: String!
    $canProvide: String!
    $isTemporary: String!
    $veterinarian: String!
    $vetPhone: String!
    $reference: String!
    $refPhone: String!
    $petName1: String
    $petType1: String
    $petBreed1: String
    $petAge1: String
    $petName2: String
    $petType2: String
    $petBreed2: String
    $petAge2: String
    $petName3: String
    $petType3: String
    $petBreed3: String
    $petAge3: String
    $petName4: String
    $petType4: String
    $petBreed4: String
    $petAge4: String
    $petName5: String
    $petType5: String
    $petBreed5: String
    $petAge5: String
    $petName6: String
    $petType6: String
    $petBreed6: String
    $petAge6: String
  ) {
    sendFormFoster(
      fullName: $fullName
      email: $email
      phone: $phone
      address: $address
      city: $city
      state: $state
      zip: $zip
      residenceType: $residenceType
      rentOrOwn: $rentOrOwn
      adults: $adults
      familiarWithDogs: $familiarWithDogs
      allergiesToDogs: $allergiesToDogs
      numPets: $numPets
      expectations: $expectations
      experience: $experience
      lookingToAdopt: $lookingToAdopt
      petsDied: $petsDied
      explainPetsDied: $explainPetsDied
      hoursLeftAlone: $hoursLeftAlone
      primaryCaretaker: $primaryCaretaker
      careAssistance: $careAssistance
      secondaryCaretaker: $secondaryCaretaker
      petsGetAlong: $petsGetAlong
      whereKeptDay: $whereKeptDay
      whereKeptNight: $whereKeptNight
      hasFence: $hasFence
      describeConfinement: $describeConfinement
      hasIndoorConfinement: $hasIndoorConfinement
      describeIndoorConfinement: $describeIndoorConfinement
      hasOutdoorShelter: $hasOutdoorShelter
      describeOutdoorShelter: $describeOutdoorShelter
      hasIsolationArea: $hasIsolationArea
      describeIsolationArea: $describeIsolationArea
      canAcceptStray: $canAcceptStray
      describeFeeding: $describeFeeding
      typeOfFood: $typeOfFood
      hasConsideredNegatives: $hasConsideredNegatives
      canCareForSick: $canCareForSick
      canGiveMedicine: $canGiveMedicine
      canCareFixed: $canCareFixed
      injuryIsUpsetting: $injuryIsUpsetting
      canTransport: $canTransport
      preferredGender: $preferredGender
      preferredSize: $preferredSize
      preferredAge: $preferredAge
      lengthOfCare: $lengthOfCare
      canAcceptLitter: $canAcceptLitter
      canProvide: $canProvide
      isTemporary: $isTemporary
      veterinarian: $veterinarian
      vetPhone: $vetPhone
      reference: $reference
      refPhone: $refPhone
      petName1: $petName1
      petType1: $petType1
      petBreed1: $petBreed1
      petAge1: $petAge1
      petName2: $petName2
      petType2: $petType2
      petBreed2: $petBreed2
      petAge2: $petAge2
      petName3: $petName3
      petType3: $petType3
      petBreed3: $petBreed3
      petAge3: $petAge3
      petName4: $petName4
      petType4: $petType4
      petBreed4: $petBreed4
      petAge4: $petAge4
      petName5: $petName5
      petType5: $petType5
      petBreed5: $petBreed5
      petAge5: $petAge5
      petName6: $petName6
      petType6: $petType6
      petBreed6: $petBreed6
      petAge6: $petAge6
    ) {
      status
      orderNumber
    }
  }
`;
