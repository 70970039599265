import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import { EVENT_IS_RUNNING } from '../../constants';

export const FormsPage = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Forms</h1>
        <p>
          Here are our forms for applications, event registrations, and more:
        </p>
        <ul className="space-y-4">
          {/* events forms */}
          {EVENT_IS_RUNNING.DOGFEST_REGISTRATION && (
            <li>
              <Link to="/forms/dogtoberfest" className="anchor">
                DogtoberFest 2024 Registration
              </Link>
            </li>
          )}
          {/* --events forms*/}
          <li>
            <a
              href="https://forms.gle/JrwPMH3Qz4Xyzq4o9"
              target="_blank"
              rel="noreferrer"
              className="anchor"
            >
              Dog Application
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/wagsequim-162ee.appspot.com/o/pdf%2FDogAdoptionApplication.pdf?alt=media&token=d63a4c4e-fa55-4437-8484-a36479727d3b"
              target="_blank"
              rel="noreferrer"
              className="anchor"
            >
              Dog Application (Print & Mail Option)
            </a>
          </li>
          <li>
            <Link to="/forms/volunteer" className="anchor">
              Volunteer Application
            </Link>
          </li>
          <li>
            <Link to="/forms/foster" className="anchor">
              Foster Dog Application
            </Link>
          </li>
        </ul>
      </Section>
    </Page>
  );
};
