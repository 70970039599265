import { ReactElement, useState } from 'react';
import moment from 'moment';

import { IPost } from '../../../types/commonTypes';
import { EditorViewer } from '../EditorViewer';

export const Post = ({ blocks, date, updated }: IPost): ReactElement => {
  const [post] = useState({ blocks: JSON.parse(blocks) });
  const dateString = moment(date).format('MMM D YYYY, h:mm A');
  const updatedString = moment(updated).format('MMM D YYYY, h:mm A');

  return (
    <div className="bg-gray-50 py-4 px-6 sm:py-12 sm:px-16 shadow-md rounded-md">
      <p className="font-semibold">{dateString}</p>
      {updated !== '' && (
        <p className="text-gray-400 italic text-xs">edited {updatedString}</p>
      )}
      <EditorViewer data={post} />
    </div>
  );
};
