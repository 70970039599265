import { ReactElement } from 'react';
import FormLayout from '../layout/FormLayout';

interface FormikInputProps {
  keyName: any;
  label: string;
  required?: boolean;
  className?: string;
  formik: any;
  type?: string;
  placeholder?: string;
  options?: TOptions;
  onChange?: any;
  maxLength?: number;
  max?: number;
  min?: number;
  floor?: boolean;
  labelClassName?: string;
}

interface TOptionObject {
  value: string;
  text: string;
}

type TOptions = (string | number | TOptionObject)[];

const FormikInput = ({
  keyName,
  className,
  formik,
  type = 'text',
  placeholder,
  options,
  label,
  required = false,
  onChange = formik.handleChange,
  maxLength = 64,
  min,
  max,
  floor,
  labelClassName,
}: FormikInputProps): ReactElement => {
  const standardTypes = ['text', 'email', 'password', 'tel', 'number'];

  const numberValue =
    type === 'number' && floor
      ? Math.floor(formik.values[keyName])
      : formik.values[keyName];

  const input = () => {
    if (standardTypes.includes(type)) {
      return (
        <input
          id={keyName}
          name={keyName}
          className={className}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={numberValue}
          onBlur={formik.handleBlur}
          pattern={type === 'tel' ? '[0-9]{3}-[0-9]{2}-[0-9]{3}' : undefined}
          maxLength={maxLength}
          min={min}
          max={max}
        />
      );
    } else if (type === 'textarea') {
      return (
        <textarea
          id={keyName}
          name={keyName}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          value={formik.values[keyName]}
          onBlur={formik.handleBlur}
          maxLength={maxLength || 5000}
        />
      );
    } else if (type === 'dollar') {
      return (
        <div className={className + ' relative'}>
          <div className="absolute left-4 top-2.5 pointer-events-none">$</div>
          <input
            id={keyName}
            name={keyName}
            className="w-full pl-6"
            type="number"
            placeholder="123"
            onChange={onChange}
            value={Number(formik.values[keyName]).toString()}
            onBlur={formik.handleBlur}
            min={0}
            step="1"
            onWheel={(e) => e.currentTarget.blur()}
            maxLength={maxLength}
          />
        </div>
      );
    } else if (type === 'select') {
      return (
        <select
          id={keyName}
          className={className}
          name={keyName}
          placeholder={placeholder}
          onChange={onChange}
          value={formik.values[keyName]}
          onBlur={formik.handleBlur}
        >
          <option key={`${keyName}_select`} value="">
            Select
          </option>
          {options &&
            options.length > 0 &&
            options.map((option, key) => {
              if (
                typeof option === 'object' &&
                !Array.isArray(option) &&
                option !== null
              ) {
                return (
                  <option key={`${keyName}_${key}`} value={option.value}>
                    {option.text}
                  </option>
                );
              } else {
                return <option key={`${keyName}_${key}`}>{option}</option>;
              }
            })}
        </select>
      );
    } else if (type === 'checkbox') {
      return (
        <fieldset
          id={keyName}
          className={className}
          name={keyName}
          onChange={onChange}
          onBlur={formik.handleBlur}
        >
          {options &&
            options.length > 0 &&
            options.map((option, key) => {
              return (
                <div key={`${keyName}_${key}`}>
                  <input
                    className="mr-2"
                    id={`${option}-${key}`}
                    type="checkbox"
                    name={keyName}
                    value={option as string}
                  />
                  <span className="checkmark" />
                  <label htmlFor={`${option}-${key}`}>{option}</label>
                </div>
              );
            })}
        </fieldset>
      );
    }
  };

  const labelText = (
    <>
      {required && <span className="text-red-800">*</span>} {label}
    </>
  );

  return (
    <FormLayout.Container>
      <label className={`font-medium ${labelClassName}`} htmlFor={keyName}>
        {labelText}
      </label>
      {input()}
      <FormLayout.Error>
        {formik.touched[keyName] && formik.errors[keyName]}
      </FormLayout.Error>
    </FormLayout.Container>
  );
};

export default FormikInput;
