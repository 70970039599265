import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../helpers';

interface ArticlePreviewProps {
  title: string;
  date: string;
  imgUrl: string;
  articleId: string;
  category: string;
  hideDate?: boolean;
}

export const ArticlePreview = (props: ArticlePreviewProps): ReactElement => {
  const { title, date, imgUrl, articleId, category, hideDate } = props;

  return (
    <Link to={`/articles/${category}/${articleId}`}>
      <article className="bg-gray-900 w-full h-48 sm:h-64 text-gray-100 mb-4 sm:mb-8 relative overflow-hidden rounded-xl">
        <div
          className="absolute w-full h-full opacity-60"
          aria-label={`${title} header image`}
          style={{
            backgroundImage: `url(${imgUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '50% 20%',
          }}
        />
        <div
          className="absolute bg-gradient-to-t from-gray-900
         w-full h-full hover:opacity-80"
        />
        <div className="absolute left-4 pr-4 bottom-4 text-left pointer-events-none">
          <h3 className="text-xl sm:text-3xl drop-shadow-lg mb-0">{title}</h3>
          {!hideDate && (
            <p className="text-md sm:text-lg">{formatDate(date)}</p>
          )}
        </div>
      </article>
    </Link>
  );
};
