import { ReactElement } from 'react';
import { formatDollar } from '../../../helpers';

interface IOrder {
  item: string;
  amount?: number;
  cost: number;
}

interface OrderTotalProps {
  order: IOrder[];
  total: string | number;
}

const OrderTotal = ({ order, total }: OrderTotalProps): ReactElement => {
  return (
    <div className="max-w-md mt-6">
      <div className="flex border-b-2 border-dashed font-medium">
        <p className="w-40">Item</p>
        <p className="w-8">#</p>
        <p>Donation</p>
      </div>
      {order.map((item, key) => (
        <div key={key} className="flex border-b-2 border-dashed">
          <p className="w-40">{item.item}</p>
          <p className="w-8">{item.amount}</p>
          <p>{formatDollar(item.cost)}</p>
        </div>
      ))}
      <div className="flex mt-8 mb-12 text-2xl">
        <p className="w-48">Total:</p>
        <p className="">{formatDollar(total)}</p>
      </div>
    </div>
  );
};

export default OrderTotal;
