import { ReactElement } from 'react';
import { WrapperProps } from '../../../types/commonTypes';

interface BoxProps {
  theme?: Theme;
  addMarginBottom?: boolean;
  addMarginTop?: boolean;
}

type Theme = 'dark' | 'red';

// theme options and strings
const themeClassNames = (theme: Theme): string => {
  switch (theme) {
    case 'red':
      return 'bg-red-800 text-gray-100';
    case 'dark':
    default:
      return 'bg-gray-800 text-gray-100';
  }
};

// these should match the top/bottom padding on Section to make spacing consistent when not using Section to wrap Box
const addMarginBottomClassNames = (isOn: boolean) =>
  isOn ? 'mb-8 sm:mb-16' : '';

const addMarginTopClassNames = (isOn: boolean) => (isOn ? 'mt-8 sm:mt-16' : '');
// --end theme options

export const Box = (props: WrapperProps & BoxProps): ReactElement => {
  const theme = props.theme || 'dark';
  const addMarginBottom = props.addMarginBottom || false;
  const addMarginTop = props.addMarginTop || false;

  return (
    <div
      className={`rounded-xl shadow-md py-8 px-8 sm:py-12 sm:px-12 space-y-4 ${themeClassNames(
        theme
      )} ${addMarginBottomClassNames(
        addMarginBottom
      )}  ${addMarginTopClassNames(addMarginTop)}`}
    >
      {props.children}
    </div>
  );
};
