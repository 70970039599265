import { eventIsRunning } from './helpers';

export const API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql';

export const PAYPAL_URL =
  'https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=VT7DJ8JFNKV4L';

// edit event start and end dates here
export const EVENT_DATES = {
  OPEN_HOUSE: {
    START: 'Jun 1, 2024, 00:00:00',
    END: 'Aug 11, 2024, 00:00:00',
  },
  WINERY: {
    START: 'Jun 1, 2024, 00:00:00',
    END: 'Aug 18, 2024, 00:00:00',
  },
  DOGFEST_SAVE: {
    START: 'Jun 1, 2024, 00:00:00',
    END: 'Aug 1, 2024, 00:00:00',
  },
  DOGFEST_EVENT: {
    START: 'Aug 1, 2024, 00:00:00',
    END: 'Oct 20, 2024, 00:00:00',
  },
  DOGFEST_REGISTRATION: {
    START: 'Aug 1, 2024, 00:00:00',
    END: 'Oct 20, 2024, 00:00:00',
  },
};

// boolean values for showing events
export const EVENT_IS_RUNNING = {
  // EVENT_NAME: eventIsRunning(
  //   EVENT_DATES.EVENT_NAME.START,
  //   EVENT_DATES.EVENT_NAME.END
  // ),
  OPEN_HOUSE: eventIsRunning(
    EVENT_DATES.OPEN_HOUSE.START,
    EVENT_DATES.OPEN_HOUSE.END
  ),
  WINERY: eventIsRunning(EVENT_DATES.WINERY.START, EVENT_DATES.WINERY.END),
  DOGFEST_SAVE: eventIsRunning(
    EVENT_DATES.DOGFEST_SAVE.START,
    EVENT_DATES.DOGFEST_SAVE.END
  ),
  DOGFEST_EVENT: eventIsRunning(
    EVENT_DATES.DOGFEST_EVENT.START,
    EVENT_DATES.DOGFEST_EVENT.END
  ),
  DOGFEST_REGISTRATION: eventIsRunning(
    EVENT_DATES.DOGFEST_REGISTRATION.START,
    EVENT_DATES.DOGFEST_REGISTRATION.END
  ),
};

// true if any events are currently running
export const EVENTS_ARE_RUNNING = Object.values(EVENT_IS_RUNNING).some(
  (event) => event
);

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
