import { ReactElement } from 'react';
import { useLocation } from 'react-router';

import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FormConfirmation = (): ReactElement => {
  const query = useQuery();
  const formNumber = query.get('formNumber');

  return (
    <Page>
      <Section>
        <h1>Thank You For Applying!</h1>
        <div className="mt-6">
          <div className="mt-8 mb-12 text-2xl">
            <p>Form submission ID:</p>
            <p>{formNumber ? formNumber : ''}</p>
          </div>
        </div>
        <p>
          Your application has been submitted. WAG will reach out to you about
          your application soon.
        </p>
      </Section>
    </Page>
  );
};

export default FormConfirmation;
