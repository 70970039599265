import { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import logo from '../../../images/WAG_logo.png';

interface NavItem {
  label: string;
  to: string;
}

interface FullHeaderProps {
  navItems: NavItem[];
}

interface MobileHeaderProps {
  navItems: NavItem[];
  showMobileMenu: boolean;
  handleMobileMenu: () => void;
}

interface StyledLinkProps {
  navItem: NavItem;
  size: 'full' | 'mobile';
}

const navItems: NavItem[] = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Updates',
    to: '/updates',
  },
  {
    label: 'Adoptable Dogs',
    to: '/dogs/1',
  },
  {
    label: 'Articles & More',
    to: '/articles/media',
  },
  {
    label: 'How You Can Help',
    to: '/help',
  },
  {
    label: 'Events',
    to: '/events',
  },
  {
    label: 'Forms',
    to: '/forms',
  },
  {
    label: 'Contact Us',
    to: '/contact',
  },
];

export const Header = (): ReactElement => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const history = useHistory();

  // On location change, close mobile menu, scroll to top
  useEffect(() => {
    const unlisten = history.listen(() => {
      setShowMobileMenu(false);
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, [history]);

  function handleMobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }

  return (
    <header className="header bg-red-800">
      <FullHeader navItems={navItems} />
      <MobileHeader
        navItems={navItems}
        showMobileMenu={showMobileMenu}
        handleMobileMenu={handleMobileMenu}
      />
    </header>
  );
};

const FullHeader = ({ navItems }: FullHeaderProps): ReactElement => {
  return (
    <div className="header-full container mx-auto flex-row items-center px-12 py-4 bg-red-800">
      <Link className="mb-1" to="/">
        <div className="w-28 xl:w-36 mx-auto">
          <img className="mx-auto" src={logo} alt="WAG logo" />
        </div>
      </Link>
      <div className="inline-block w-full pl-8 xl:pl-12">
        <h1 className="text-gray-100 mb-3 mt-2">
          Welfare for Animals Guild - WAG
        </h1>
        <nav className="flex items-center flex-row justify-between w-full">
          {navItems.map((navItem, key) => (
            <StyledLink navItem={navItem} size="full" key={`fullNav${key}`} />
          ))}
        </nav>
      </div>
    </div>
  );
};

const MobileHeader = ({
  navItems,
  showMobileMenu,
  handleMobileMenu,
}: MobileHeaderProps): ReactElement => {
  const style = {
    menuButtonStyle: showMobileMenu ? ' bg-gray-800 ' : ' bg-red-800 ',
    menuStyle: showMobileMenu ? '' : ' hidden ',
  };

  return (
    <div className="header-mobile w-full h-16 bg-gray-100">
      <div className="flex fixed top-0 z-50 mx-auto flex-row items-center h-16 w-full bg-red-800">
        <button
          className={
            'text-gray-200 w-16 h-full text-4xl flex items-center justify-center mr-2 flex-shrink-0' +
            style.menuButtonStyle
          }
          onClick={handleMobileMenu}
        >
          {showMobileMenu ? (
            <FontAwesomeIcon icon={faTimes as any} />
          ) : (
            <FontAwesomeIcon icon={faBars as any} />
          )}
        </button>
        <div className="flex items-center h-full">
          <div className="w-12 mx-auto mr-4 flex-shrink-0">
            <img className="mx-auto" src={logo} alt="WAG logo" />
          </div>
          <h1 className="text-xl text-gray-100 my-auto pr-2">
            Welfare for Animals Guild - WAG
          </h1>
        </div>
      </div>
      <nav
        className={
          'flex flex-col fixed top-0 h-full pt-16 w-full bg-gray-700 z-40 shadow-2xl overflow-y-scroll' +
          style.menuStyle
        }
      >
        {navItems.map((navItem, key) => (
          <StyledLink navItem={navItem} size="mobile" key={`mobileNav${key}`} />
        ))}
      </nav>
    </div>
  );
};

const StyledLink = ({ navItem, size = 'full' }: StyledLinkProps) => {
  const style = { style: null as any, className: null as any };

  switch (size) {
    case 'full':
      style.style = {
        borderBottomColor: 'rgba(229, 231, 235, 0.8)',
      };
      style.className =
        'text-gray-200 py-1 xl:py-2 px-1 xl:px-3 hover:rounded-full border-2 border-b-gray-200 border-red-800 hover:border-gray-200 hover:bg-gray-200 hover:text-red-800 text-sm xl:text-lg text-center';
      break;
    case 'mobile':
      style.style = { borderBottom: '1px solid rgba(229, 231, 235, 0.2)' };
      style.className =
        'text-2xl py-2 pl-4 text-gray-200 hover:bg-gray-100 hover:text-gray-900';
      break;
  }

  return (
    <Link style={style.style} className={style.className} to={navItem.to}>
      {navItem.label}
    </Link>
  );
};
