import { ReactElement } from 'react';
import { replaceCharCode } from '../../../helpers';

import { Dog } from '../../../types/commonTypes';

import dognoimage from '../../../images/dognoimage.png';

interface AnimalCardProps {
  dog: Dog;
}

export const DogCard = (props: AnimalCardProps): ReactElement => {
  const { name, breeds, photoUrl, url, description } = props.dog;
  return (
    <div
      style={{ border: '1px solid #ddd' }}
      className="text-left rounded-xl mx-auto shadow-lg"
    >
      {/* Photo */}
      <div className="h-72 rounded-t-md flex items-center overflow-hidden bg-gray-300">
        <img
          src={photoUrl}
          onError={(e) => {
            (e.target as HTMLImageElement).src = dognoimage;
          }}
          className="h-full w-full object-cover"
          alt={name}
        />
      </div>
      {/* Text */}
      <div className="p-4 h-64 flex flex-col">
        <div className="relative overflow-ellipsis overflow-hidden h-44">
          {/* Name */}
          <h3 className="text-2xl mb-0">{name}</h3>
          {/* Breed */}
          <p className="italic font-light text-sm mb-2">{`${breeds.primary}${
            breeds.secondary !== null ? ' & ' + breeds.secondary + ' Mix' : ''
          }`}</p>
          {/* Short description */}
          <p>{replaceCharCode(description)}</p>
          <div className="absolute w-full bottom-0 h-8 bg-gradient-to-t from-gray-100" />
          {/* Link to PetFinder */}
        </div>
        <a
          className="btn btn--small btn--primary mt-auto mx-auto"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          Meet {name}
        </a>
      </div>
    </div>
  );
};
