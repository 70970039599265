import { ReactElement, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import Article from '../../components/alerts/Loading/Article';
import NewsletterList from '../../components/dataViews/NewsletterList';
import NewsletterLoader from '../../components/dataViews/NewsletterLoader';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import Ranch from './Ranch';
import Articles from './Articles';
import Cbcc from './Cbcc';

export const ArticlesMedia = (): ReactElement => {
  const history = useHistory();
  const [pathname, setPathname] = useState('');

  // Style/highlight nav links by checking pathname
  const navLinkStyle = (path: string) => {
    let style = 'mr-4 mb-2 ';
    if (pathname === path) {
      style += 'link-selected';
    } else {
      style += 'link';
    }
    return style;
  };

  // Listen for history.location.pathname changes to highlight category buttons
  useEffect(() => {
    setPathname(history.location.pathname);
    const unlisten = history.listen(() => {
      // Update pathname (state), causing Links to highlight/unhighlight
      setPathname(history.location.pathname);
    });
    return unlisten;
  }, [history]);

  return (
    <Page>
      {/* Category Navigation */}
      <Section>
        <h1>Articles & More</h1>
        <nav className="flex flex-wrap sm:flex-row flex-col sm:justify-start justify-center">
          <Link
            className={navLinkStyle('/articles/media')}
            to="/articles/media"
          >
            Media
          </Link>
          <Link
            className={navLinkStyle('/articles/newsletters')}
            to="/articles/newsletters"
          >
            Newsletters
          </Link>
          <Link className={navLinkStyle('/articles/cbcc')} to="/articles/cbcc">
            CBCC Prison Program
          </Link>
          <Link
            className={navLinkStyle('/articles/ranch')}
            to={'/articles/ranch'}
          >
            Halfway Home Ranch
          </Link>
        </nav>
      </Section>
      {/* Pages */}
      <Switch>
        <Route exact path="/articles/media">
          <Articles />
        </Route>
        <Route exact path="/articles/newsletters">
          <NewsletterList />
        </Route>
        <Route exact path="/articles/cbcc">
          <Cbcc />
        </Route>
        <Route exact path="/articles/newsletters/:id">
          <NewsletterLoader />
        </Route>
        {/* Single-article pages */}
        <Route exact path="/articles/ranch">
          <Ranch />
        </Route>
        {/* Display selected article, all other Routes must be 'exact' */}
        <Route path="/articles/:_/:id">
          <Article />
        </Route>
      </Switch>
    </Page>
  );
};
