import { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { formatDollar } from '../../helpers';

import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import PayPalButton from '../../components/buttons/PayPalButton';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DogtoberfestConfirmation = (): ReactElement => {
  const query = useQuery();
  const orderNumber = query.get('orderNumber');
  const total = query.get('total');

  return (
    <Page>
      <Section>
        <h1>Thank You For Registering For DogtoberFest!</h1>
        <div className="mt-6">
          <div className="mt-8 mb-12 text-2xl">
            <p>Form submission ID:</p>
            <p>{orderNumber ? orderNumber : ''}</p>
            <br />
            <p>Total Donation:</p>
            <p>{total ? formatDollar(total) : ''}</p>
          </div>
        </div>
        <p>
          Please proceed to PayPal to make your donation and complete the
          registration process. If you can leave a comment in the payment with
          your name and form submission ID, it will help us confirm your
          registration more quickly:
        </p>
        <PayPalButton />
        <p>Thank you for supporting WAG!</p>
      </Section>
    </Page>
  );
};

export default DogtoberfestConfirmation;
