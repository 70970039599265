import { ReactElement } from 'react';
import PostList from '../../components/dataViews/PostList';

import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';

export const Updates = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Updates</h1>
        <PostList />
      </Section>
    </Page>
  );
};
