import { ReactElement } from 'react';

interface SponsorDogProps {
  imgUrl: string;
  name: string;
  description: string;
}

export const SponsorDogCard = (props: SponsorDogProps): ReactElement => {
  const { imgUrl, name, description } = props;
  return (
    <div className="inline-block rounded-xl shadow-sm w-full p-6 my-2 bg-gray-200">
      <div className="sm:float-left mx-auto sm:ml-0 sm:mr-6 mb-6 rounded-full w-32 h-32 overflow-hidden flex justify-center items-center bg-gray-800">
        <img className="w-full rounded-full" src={imgUrl} alt={name} />
      </div>
      <h3 className="text-center sm:text-left text-2xl font-medium mb-2">{name}</h3>
      <p>{description}</p>
    </div>
  );
};
