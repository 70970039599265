import { ReactElement } from 'react';
import Form from '../../components/forms';
import Page from '../../components/layout/Page';

const FosterFormPage = (): ReactElement => {
  return (
    <Page>
      <Form.Foster />
    </Page>
  );
};

export default FosterFormPage;
