// From editorjs-parser
export const edjsParserConfig = {
  simpleImage: function (data, config) {
    const imageConditions = `${data.stretched ? 'img-fullwidth' : ''} ${
      data.withBorder ? 'img-border' : ''
    } ${data.withBackground ? 'img-bg' : ''}`;
    const imgClass = config.image.imgClass || '';
    let imageSrc;

    if (data.url) {
      // simple-image was used and the image probably is not uploaded to this server
      // therefore, we use the absolute path provided in data.url
      // so, config.image.path property is useless in this case!
      imageSrc = data.url;
    } else if (config.image.path === 'absolute') {
      imageSrc = data.file.url;
    } else {
      imageSrc = config.image.path.replace(
        /<(.+)>/,
        (match, p1) => data.file[p1]
      );
    }

    if (config.image.use === 'img') {
      return `<img class="${imageConditions} ${imgClass}" src="${imageSrc}" alt="${data.caption}">`;
    } else if (config.image.use === 'figure') {
      const figureClass = config.image.figureClass || '';
      const figCapClass = config.image.figCapClass || '';

      return `<figure class="${figureClass}"><img class="${imgClass} ${imageConditions}" src="${imageSrc}" alt="${data.caption}"><figcaption class="${figCapClass}">${data.caption}</figcaption></figure>`;
    }
  },
};
