import { ReactElement } from 'react';

const Error = ({ children }: any): ReactElement => {
  const className = 'text-red-800 text-xs mt-1 h-4';
  if (children) {
    return <p className={className}>{children}</p>;
  } else {
    return <div className={className} />;
  }
};

export default Error;
