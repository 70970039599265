import { RefObject } from 'react';
import edjsHTML from 'editorjs-html';
import { scroller } from 'react-scroll';

import { ArticleType } from './types/commonTypes';

// regex to remove scripts and normalize HTML tags in fetched data for articles/dogs
const scriptTagRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
const headerOpenRegex = /<h1>|<h2>|<h3>|<h4>|<h5>|<h6>/;
const headerCloseRegex = /<\/h1>|<\/h2>|<\/h3>|<\/h4>|<\/h5>|<\/h6>/;
const charCodeRegex = /&#039;|&quot;|&nbsp;|&amp;|nbsp;|amp;/gi;

// other regex
export const validPhoneRegex =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/g;

// dictionary to replace or remove character code strings from fetched dog descriptions
const charCodeDict: { [key: string]: string } = {
  '&#039;': "'",
  '&quot;': '"',
  '&nbsp;': '',
  '&amp;': '',
  'nbsp;': '',
  'amp;': '',
};

// create and render html given an array of html elements (in string form) and a ref
export const renderHtmlFromStrings = (
  htmlStrings: string[],
  ref: RefObject<any>
) => {
  try {
    if (ref.current !== undefined) {
      htmlStrings.forEach((el, i) => {
        const fragment = document.createRange().createContextualFragment(el);
        ref.current.appendChild(fragment.children[0]);
      });
    }
  } catch {
    console.log('Error: could not generate HTML');
  }
};

// parse JSON for Editor.js data
export const parseEditorJSON = (JSON: object): string[] => {
  const edjsParser = edjsHTML();
  try {
    return edjsParser.parse(JSON);
  } catch {
    return [];
  }
};

// remove <script> elements and fix all header levels from an html string before setting innerHTML
export const cleanHTMLString = (htmlString: String): String => {
  return htmlString
    .replace(scriptTagRegex, '')
    .replace(headerOpenRegex, '<h3>')
    .replace(headerCloseRegex, '</h3>');
};

// store a token string in local storage
export const storeToken = (token: string) => {
  if (token !== undefined) {
    localStorage.setItem('token', `Bearer ${token}`);
  } else {
    console.log('Error: token undefined');
  }
};

// get the first image from Editor blocks to use as header image
export const getFirstImgUrl = (blocks: any[]): string => {
  try {
    for (let i = 0; i < blocks.length; i++) {
      if (
        blocks[i].type.toLowerCase().includes('image') &&
        blocks[i].data?.file?.url
      ) {
        return blocks[i].data.file.url;
      }
    }
  } catch (err) {
    console.log(err);
  }
  return '';
};

// parse stringified blocks in a fetched article and return the article
export const parseArticle = (
  fetchedArticle: ArticleType
): { article: ArticleType; valid: boolean } => {
  const parsed = { article: fetchedArticle, valid: false };
  try {
    let parsedBlocks = null;
    // if blocks are in string (JSON stringified), parse them
    if (typeof fetchedArticle.blocks === 'string') {
      parsedBlocks = JSON.parse(fetchedArticle.blocks);
    }
    // if parsed blocks are now an array type, validate the return
    if (Array.isArray(parsedBlocks)) {
      parsed.article = { ...fetchedArticle, blocks: parsedBlocks };
      parsed.valid = true;
    }
  } catch (err) {
    console.log(err);
  }
  return parsed;
};

// format dates from fetched articles from yyyy-mm-dd to Month dd, yyyy
export const formatDate = (date: string): string => {
  const split = date.split('-');
  let month = '';
  switch (split[1]) {
    case '01':
      month = 'January';
      break;
    case '02':
      month = 'February';
      break;
    case '03':
      month = 'March';
      break;
    case '04':
      month = 'April';
      break;
    case '05':
      month = 'May';
      break;
    case '06':
      month = 'June';
      break;
    case '07':
      month = 'July';
      break;
    case '08':
      month = 'August';
      break;
    case '09':
      month = 'September';
      break;
    case '10':
      month = 'October';
      break;
    case '11':
      month = 'November';
      break;
    default:
      month = 'December';
  }
  return `${month} ${split[2]}, ${split[0]}`;
};

// sort articles based on a date string
export const sortArticlesByDate = (articles: ArticleType[]): ArticleType[] => {
  try {
    const unsorted = [...articles];
    // sort by day
    let sorted = unsorted.sort((a1, a2) => {
      if (Number(a1.date.split('-')[2]) - Number(a2.date.split('-')[2]) > 0) {
        return -1;
      } else if (
        Number(a1.date.split('-')[2]) - Number(a2.date.split('-')[2]) <
        0
      ) {
        return 1;
      } else {
        return 0;
      }
    });
    // sort by month
    sorted = sorted.sort((a1, a2) => {
      if (Number(a1.date.split('-')[1]) - Number(a2.date.split('-')[1]) > 0) {
        return -1;
      } else if (
        Number(a1.date.split('-')[1]) - Number(a2.date.split('-')[1]) <
        0
      ) {
        return 1;
      } else {
        return 0;
      }
    });
    // sort by year
    sorted = sorted.sort((a1, a2) => {
      if (Number(a1.date.split('-')[0]) - Number(a2.date.split('-')[0]) > 0) {
        return -1;
      } else if (
        Number(a1.date.split('-')[0]) - Number(a2.date.split('-')[0]) <
        0
      ) {
        return 1;
      } else {
        return 0;
      }
    });
    return sorted;
  } catch (err) {
    console.log(err);
    return articles;
  }
};

// given a list of objects and a schema, return arrays of objects that match and don't match
export const filterBySchema = (
  items: { [key: string]: any }[],
  schema: { [key: string]: string },
  strict = false
): { [key: string]: any }[][] => {
  let matched = [];
  let failed = [];
  for (let i = 0; i < items.length; i++) {
    // if the item is not an object, skip all other checks
    if (Object.prototype.toString.call(items[i]) !== '[object Object]') {
      failed.push(items[i]);
      continue;
    }
    // if strict is true, check that the item has the same number of keys as the schema
    if (strict && Object.keys(items[i]).length !== Object.keys(schema).length) {
      failed.push(items[i]);
      continue;
    }
    let matches = 0;
    for (const key in schema) {
      // Check array
      if (schema[key] === 'array') {
        if (Object.prototype.toString.call(items[i][key]) === '[object Array]')
          matches += 1;
        // Check object strictly
      } else if (schema[key] === 'object') {
        if (Object.prototype.toString.call(items[i][key]) === '[object Object]')
          matches += 1;
        // Check other types
      } else if (
        items[i].hasOwnProperty(key) &&
        typeof items[i][key] === schema[key]
      ) {
        matches += 1;
      }
    }
    matches === Object.keys(schema).length
      ? matched.push(items[i])
      : failed.push(items[i]);
  }
  return [matched, failed];
};

// use react-scroller to smoothly scroll to a ref
export const scrollToRef = (className: string) =>
  scroller.scrollTo(className, {
    duration: 600,
    delay: 0,
    smooth: 'easeOutQuad',
  });

export const shuffle = (array: any[]): any[] => {
  let currentIndex = array.length,
    randomIndex;
  // while there remain elements to shuffle
  while (currentIndex !== 0) {
    // pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // swap it with the current element
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

// replace character codes in string with character from dictionary
export const replaceCharCode = (str: string) => {
  return str.replace(
    charCodeRegex,
    (matched: string) => charCodeDict[matched] as string
  );
};

// format a phone number from a string input, filter out non-number characters
export const formatPhone = (value: string) => {
  if (!value) return value;
  const phone = value.replace(/[^\d]/g, '');
  if (phone.length < 4) return phone;
  if (phone.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
  if (phone.length >= 7)
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
};

// remove extra cents from dollar amount, make positive, use for donation amounts in forms
export const sanitizeDollars = (value: number, max = 10000) => {
  value = Math.abs(Math.trunc(value));
  if (value > max) value = max;
  return value;
};

// format number as USD
export const formatDollar = (value: number | string) => {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

// set up dates and environment to show special event information
export const eventIsRunning = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();
  return (
    process.env.REACT_APP_SHOW_EVENT === 'true' ||
    (now > startDate && now < endDate)
  );
};

/**
 * get a number from a string if it's the first word
 * @param s
 * @returns
 */
export const parseFirstNumber = (s: string) => {
  let num = 0;
  try {
    num = Number(s.split('')[0]);
  } catch {}
  return num;
};

/**
 * create a mock promise that resolves after some time
 */
export const mockPromise = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve('submitted'), 1000);
  });
