import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CarouselSwiper from '../../components/layout/CarouselSwiper';
import FlexItemsContainer from '../../components/layout/FlexItemsContainer';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import SponsorDogCard from '../../components/cards/SponsorDogCard';

import { filterBySchema, shuffle } from '../../helpers';
import { GET_SPONSOR_DOGS } from '../../api/queries';
import { SponsorDog } from '../../types/commonTypes';
import volunteerSlides from '../../images/volunteer/slides';

const sponsorDogSchema = {
  id: 'string',
  imgUrl: 'string',
  name: 'string',
  description: 'string',
};

export const Help = (): ReactElement => {
  const { data, error } = useQuery(GET_SPONSOR_DOGS);
  const [sponsorDogs, setSponsorDogs] = useState<SponsorDog[]>();

  useEffect(() => {
    if (data)
      setSponsorDogs(
        filterBySchema(data.sponsorDogs, sponsorDogSchema)[0] as SponsorDog[]
      );
  }, [data]);

  // log query errors
  useEffect(() => {
    if (error) console.log(error);
  }, [error]);

  return (
    <Page>
      <Section>
        <h1>How You Can Help</h1>
        <p>There are many ways to help support and care for dogs at WAG:</p>
      </Section>
      <Section>
        <h2>Sponsor a WAG Dog</h2>
        <p>
          We need your help to provide ongoing care to these dogs. Could you be
          a sponsor and help us cover their monthly care? WAG will provide
          updates on your sponsored dog. If you are interested please email{' '}
          <a className="anchor" href="mailto:wagranch@yahoo.com">
            wagranch@yahoo.com
          </a>
          .
        </p>
        <div>
          {sponsorDogs &&
            sponsorDogs.map((dog) => (
              <SponsorDogCard
                key={dog.id}
                imgUrl={dog.imgUrl}
                name={dog.name}
                description={dog.description}
              />
            ))}
        </div>
        <FlexItemsContainer>
          <a
            className="btn btn--large btn--primary btn--responsive-mx"
            href="mailto:wagranch@yahoo.com"
          >
            Email Us
          </a>
        </FlexItemsContainer>
      </Section>
      <Section>
        <h2>Foster a Dog</h2>
        <p>
          WAG is always looking for loving homes that can care for and love a
          dog until they find their forever home. If you can foster a dog for a
          few weeks or months, we need you. WAG will supply all the food,
          medical costs, toys like Nylabones, etc. You just supply a warm,
          loving home until a forever home is found. You can choose whether you
          want to foster a big dog, small dog, female, male, puppies or a senior
          dog. We work closely with our foster homes making sure that the dog
          you are fostering is a good fit in your family. If you are interested,
          please fill out our Foster Parent Application form.
        </p>
        <FlexItemsContainer>
          <Link
            className="btn btn--large btn--primary btn--responsive-mx"
            to="/forms/foster"
          >
            Apply to Foster
          </Link>
          <Link
            className="btn btn--large btn--secondary btn--responsive-mx"
            to="/dogs"
          >
            Meet Our Dogs
          </Link>
        </FlexItemsContainer>
      </Section>
      <Section>
        <h2>Lasting Act of Kindness</h2>
        <p>
          WAG will help provide you with options for you to provide ongoing care
          for your pet when you are no longer able. While we are searching for
          your pet's new home, WAG will ensure that you pet is cared for and
          loved. Our goal is to find a home similar to yours and meet the needs
          of your beloved pet. By including our “Lasting Act of Kindness”
          program in your Will, it will ensure that loving care will be provided
          for your pet.
        </p>
        <FlexItemsContainer>
          <Link
            className="btn btn--large btn--primary btn--responsive-mx"
            to="/lasting-act-of-kindness"
          >
            Learn More
          </Link>
          <a
            className="btn btn--large btn--secondary btn--responsive-mx"
            href="https://firebasestorage.googleapis.com/v0/b/wagsequim-162ee.appspot.com/o/brochures%2FWAG%20-%20A%20Lasting%20Act%20of%20Kindness%20Brochure.pdf?alt=media&token=7d48cdd4-02bb-42b5-9b90-305851df98d0"
            target="_blank"
            rel="noreferrer"
          >
            Download Brochure
          </a>
        </FlexItemsContainer>
      </Section>
      <Section>
        <h2>Volunteer</h2>
        <p>
          We are always looking for Volunteers. Our volunteers walk dogs, play
          in the play yards with them, or just sit and be a quiet companion.
          However, we have many other areas in which we need your help as well.
          We need volunteers with green thumbs that can work in our beautiful
          memorial gardens, transport dogs to vet or grooming appointments,
          fundraise, work at bazaars, etc. If you have a heart for rescue and
          want to make a difference in the lives of these precious pups, please
          consider signing up as a volunteer. We would love to have you join the
          WAG family.
        </p>
        <CarouselSwiper slides={shuffle(volunteerSlides)} styles="pb-4" />
        <h3 className="text-2xl">
          Some of the things our volunteers help with:
        </h3>
        <ul className="list-disc pl-5">
          <li>Gardening at the Ranch</li>
          <li>Ranch maintenance</li>
          <li>Emergency Response Team</li>
          <li>Socializing and caring for dogs at the ranch</li>
          <li>Walking dogs</li>
          <li>Sitting with dogs on lap while reading or movie watching</li>
          <li>
            Puppy care and socialization, possibly around-the-clock bottle
            feeding
          </li>
          <li>Community education - presentations to clubs or schools</li>
          <li>Picking up/transporting dog food from the store to the Ranch</li>
          <li>Transporting dogs to veterinary clinics or the groomer</li>
          <li>Transporting supplies to foster homes</li>
          <li>
            Stocking newsletters, WAG pamphlets, and brochures at veterinary
            clinics and other businesses
          </li>
          <li>
            Visiting estate planners about Lasting Act of Kindness options
          </li>
          <li>Picking up WAG donations</li>
          <li>Helping organize fundraisers</li>
          <li>Doing laundry</li>
          <li>Taking pictures of new dog arrivals</li>
          <li>Grant writing</li>
          <li>Helping at bazaars and festivals</li>
        </ul>
        <FlexItemsContainer>
          <Link
            to="/forms/volunteer"
            className="btn btn--large btn--primary btn--responsive-mx"
          >
            Apply to Volunteer
          </Link>
        </FlexItemsContainer>
      </Section>
    </Page>
  );
};
