import { Link } from 'react-router-dom';
import { scrollToRef } from '../../../helpers';

interface PageSelectorProps {
  page: number;
  pages: number;
  baseUrl: string;
  scroller?: boolean;
}

export const PageSelector = (props: PageSelectorProps) => {
  const { page, pages, baseUrl, scroller } = props;

  const scrollUp = () => {
    if (scroller) scrollToRef('scroll-pagination');
  };

  return (
    <div className="text-right sm:py-4 space-x-2">
      {pages > 1 && page > 1 ? (
        <Link
          onClick={scrollUp}
          to={`${baseUrl}/${page - 1}`}
          className="btn btn--small btn--secondary"
        >
          Prev
        </Link>
      ) : (
        <button className="btn btn--small btn--secondary" disabled>
          Prev
        </button>
      )}
      {page < pages ? (
        <Link
          onClick={scrollUp}
          to={`${baseUrl}/${page + 1}`}
          className="btn btn--small btn--primary"
        >
          Next
        </Link>
      ) : (
        <button className="btn btn--small btn--primary" disabled>
          Next
        </button>
      )}
      <span>
        Page {page} of {pages}
      </span>
    </div>
  );
};
