import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import FlexItemsContainer from '../../components/layout/FlexItemsContainer';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';

import brochure1 from '../../images/brochure/brochure1.png';
import brochure2 from '../../images/brochure/brochure2.png';
import brochure3 from '../../images/brochure/brochure3.png';
import brochure4 from '../../images/brochure/brochure4.png';
import brochure5 from '../../images/brochure/brochure5.png';
import brochure6 from '../../images/brochure/brochure6.png';

const style = {
  figure: 'flex sm:flex-row flex-col',
  figureImg: 'w-64 sm:mr-4 mb-4 sm:mb-0 mx-auto rounded-sm self-start',
  smallBox: 'bg-gray-200 p-4 rounded-sm',
};

export const LastingKindness = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Lasting Act of Kindness</h1>
        <h2 className="italic font-normal text-2xl">
          How to Secure the Future of Your Dog
        </h2>
        <img className="article-img pt-4" src={brochure1} alt="man with dog" />
      </Section>
      <Section>
        <h2>Our Message to Dog Lovers</h2>
        <p>
          Of course you love your dog and want to make sure he or she is well
          cared for after you’re gone—or when you can no longer care for your
          furry companion. WAG (Welfare for Animals Guild) has created a
          guardianship program to secure your dog’s future so that when the time
          comes that you are unable to care for your dog, WAG will provide you
          peace of mind knowing that your furry friend is in a safe and loving
          environment.
        </p>
        <h3 className="pt-4">
          The Lasting Act of Kindness Program Will Secure Your Dog’s Future
        </h3>
        <p>
          Regardless of our age or physical condition, all dog owners need to
          consider who will take care of our loyal companion once we are gone.
        </p>
        <div className={style.smallBox}>
          <p>
            <strong>There are really two options to consider:</strong>
          </p>
          <ol className="list-decimal ml-4">
            <li>Can my dog live with a friend of family member?</li>
            <li>
              Is it best to have WAG care for my pet until an adoptive family is
              found?
            </li>
          </ol>
        </div>
        <p>
          When considering a friend or family member, remember that being an
          animal lover is not the only criterion to evaluate care for your dog.
          Similar to WAG’s judicious adoption process, one should inquire about
          things such as lifestyle, working hours, or possible health problems
          that might impact a potential guardian’s ability to care for your dog
          for the duration of its life. The best case scenario is to try to find
          a home similar to yours so the dog feels secure and comfortable.
        </p>
        <img
          className="mx-auto sm:float-left sm:mr-4 rounded-sm"
          src={brochure2}
          alt="small dog"
        />
        <p>
          WAG’s Lasting Act of Kindness program was created to provide a safe
          and loving home for your pet until a suitable adoptive family can be
          found. Our adoption counselors make every effort to find the most
          suitable, permanent home for your much loved dog. Our extensive
          process includes calling references provided by you to ensure these
          prospective adopters can provide your dog with everything it needs to
          be happy. Our goal is that the adoption should be a win-win for both
          the dog and new guardian.
        </p>
        <p>
          Every dog owner is eligible for this program. WAG requests a donation
          at the time of surrender or that you make arrangements, like those
          mentioned under Estate Planning. If interested, WAG will send a
          registration packet to you. Gathering important information in advance
          is crucial and helps us to care for your pet and find a new forever
          home. No specific donation amount is required to ensure your pet
          continues to live a full and happy life under WAG’s care. However,
          there is a small processing fee required to register your dog(s).
        </p>
        <h3 className="pt-4">It's a Win-Win for the Dogs and for You!</h3>
        {/* quotes */}
        <figure className={style.figure + ' ' + style.smallBox}>
          <img
            className={style.figureImg}
            src={brochure3}
            alt="woman with two dogs"
          />
          <div>
            <blockquote>
              <em>
                "I have great peace of mind knowing the amount of love that WAG
                will put into finding my furbabies’ next home. My heart is
                content knowing the compassionate care they will receive, if the
                time comes that I can no longer care for them."
              </em>
            </blockquote>
            <figcaption>- Kathy Coakley</figcaption>
          </div>
        </figure>
        <figure className={style.figure + ' ' + style.smallBox}>
          <img
            className={style.figureImg}
            src={brochure4}
            alt="man and woman with three dogs"
          />
          <div>
            <blockquote>
              <em>
                "I have been a WAG volunteer for years and have a great love and
                respect for this wonderful organization. That’s why I’m
                comforted by the “Lasting Act of Kindness” program and knowing
                our precious dogs will be cared for and loved should something
                happen to my husband and me."
              </em>
            </blockquote>
            <figcaption>– Barbara and Doug Bolles</figcaption>
          </div>
        </figure>
        {/* -end quotes */}
      </Section>
      <Section>
        <h2>Estate Planning</h2>
        <h3>Consider a Gift to WAG</h3>
        <p>
          In addition to ensuring your dog’s future, please consider a gift to
          WAG that will help hundreds of dogs receive medical attention, and
          lots of affection, until being adopted by a well vetted family or
          individual.
        </p>
        <p>
          You can bequeath to WAG a designated amount or percentage from
          annuities, stocks and bonds, IRAs, and life insurance. Real estate and
          retirement plans are also an invaluable way to make sure WAG continues
          to rescue and care for dogs.
        </p>
        <img
          className="mx-auto sm:float-right sm:ml-4 sm:w-64 rounded-sm"
          src={brochure5}
          alt="woman with dog"
        />
        <p>
          Please talk to your attorney and tax advisor about making WAG a
          beneficiary and how that will affect your estate and possibly provide
          tax advantages.
        </p>
        <div className={style.smallBox}>
          <p>
            <strong>
              Your donation provides WAG dogs with a loving and safe shelter,
              medical care including all vaccinations, spaying and neutering,
              microchips and even behavioral and obedience training—everything
              possible to give these dogs the best opportunity for a happy
              adoption.
            </strong>
          </p>
        </div>
      </Section>
      <Section>
        <h2>Who We Are</h2>
        <h3>
          WAG (Welfare for Animals Guild) has been rescuing dogs and finding
          their forever homes for more than 20 years.
        </h3>
        <p>
          Established in 2001, WAG is the only all dog, no-kill rescue group in
          Sequim, Washington. To date, WAG has placed more than 1,900 dogs into
          loving homes. WAG depends on dedicated volunteers and, as a nonprofit
          501(c)(3) organization, is funded solely by donations and grants,
          which aid in the care and rehabilitation of dogs prior to their
          adoption. WAG is dedicated to helping abandoned, lost, neglected,
          injured, and surrendered dogs
        </p>
      </Section>
      <Section>
        <h2>Interested in Helping?</h2>
        <Link className="anchor" to="help">
          See our "How You Can Help" page
        </Link>
        <p>
          <strong>Foster Homes</strong> provide care and affection until a dog
          finds its forever home.
        </p>
        <p>
          <strong>Adoption</strong> is our goal; available dogs are always
          updated on our website and{' '}
          <a
            className="anchor"
            href="https://www.petfinder.com/member/us/wa/sequim/welfare-for-animals-guild-wag-wa53/"
            target="_blank"
            rel="noreferrer"
          >
            petfinder.com
          </a>
          .
        </p>
        <p>
          <strong>Volunteers</strong> are always needed to do anything from
          hanging out with dogs to gardening.
        </p>
        <p>
          Follow all the dogs on&nbsp;
          <a
            className="anchor"
            href="https://www.facebook.com/wagsequim/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          &nbsp;and&nbsp;
          <a
            className="anchor"
            href="https://www.instagram.com/wagwadog/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </p>
        <img
          className="article-img pt-4"
          src={brochure6}
          alt="WAG, local dog rescue, Sequim, Washington. wagsequimwa.com. 360-460-6258. Visits are by appointment only. 751 McComb RD, P.O. Box 3966, Sequim, WA 98382. WAG is a federal 501(c)(3). Tax ID# 91-2132797"
        />
        <FlexItemsContainer>
          <a
            className="btn btn--large btn--primary btn--responsive-mx"
            href="https://firebasestorage.googleapis.com/v0/b/wagsequim-162ee.appspot.com/o/brochures%2FWAG%20-%20A%20Lasting%20Act%20of%20Kindness%20Brochure.pdf?alt=media&token=7d48cdd4-02bb-42b5-9b90-305851df98d0"
            target="_blank"
            rel="noreferrer"
          >
            Download Brochure
          </a>
        </FlexItemsContainer>
      </Section>
    </Page>
  );
};
