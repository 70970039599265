import { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Footer from './components/sections/Footer';
import Header from './components/sections/Header';
import ArticlesMedia from './pages/ArticlesMedia';
import Dogs from './pages/Dogs';
import Events from './pages/Events';
import Forms from './pages/Forms';
import Home from './pages/Home';
import Help from './pages/Help';
import Contact from './pages/Contact';
import ToTopButton from './components/buttons/ToTopButton';
import AdoptedDogs from './pages/AdoptedDogs';
import LastingKindness from './pages/LastingKindness';
import DogtoberfestFormPage from './pages/Forms/DogtoberfestFormPage';
import DogtoberfestConfirmation from './pages/Forms/DogtoberfestConfirmation';
import Updates from './pages/Updates';
import VolunteerFormPage from './pages/Forms/VolunteerFormPage';
import FosterFormPage from './pages/Forms/FosterFormPage';
import FormConfirmation from './pages/Forms/FormConfirmation';
import Clinic from './pages/Events/Clinic';

const App = (): ReactElement => {
  return (
    <div className="App bg-gray-200">
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow bg-gray-100">
          <Switch>
            {/* Dog Listings */}
            <Route path="/dogs/:page">
              <Dogs />
            </Route>
            <Route path="/adopted/:page">
              <AdoptedDogs />
            </Route>

            {/* Events, Forms */}
            <Route path="/events">
              <Events />
            </Route>
            <Route exact path="/forms">
              <Forms />
            </Route>
            <Route exact path="/forms/dogtoberfest">
              <DogtoberfestFormPage />
            </Route>
            <Route exact path="/forms/dogtoberfest/confirmation">
              <DogtoberfestConfirmation />
            </Route>
            <Route exact path="/forms/volunteer">
              <VolunteerFormPage />
            </Route>
            <Route exact path="/forms/volunteer/confirmation">
              <FormConfirmation />
            </Route>
            <Route exact path="/forms/foster/confirmation">
              <FormConfirmation />
            </Route>
            <Route exact path="/forms/foster">
              <FosterFormPage />
            </Route>
            <Route exact path="/donation/spay-neuter-clinic">
              <Clinic />
            </Route>

            {/* Articles, Media, Feed */}
            <Route path="/updates">
              <Updates />
            </Route>
            <Route path="/articles/media">
              <ArticlesMedia />
            </Route>
            <Route path="/articles/newsletters">
              <ArticlesMedia />
            </Route>
            <Route path="/articles/cbcc">
              <ArticlesMedia />
            </Route>
            <Route path="/articles/ranch">
              <ArticlesMedia />
            </Route>

            {/* How to Help */}
            <Route path="/help">
              <Help />
            </Route>
            <Route path="/lasting-act-of-kindness">
              <LastingKindness />
            </Route>

            {/* Contact */}
            <Route path="/contact">
              <Contact />
            </Route>

            {/* Redirects */}
            <Route path="/dogs">
              <Redirect to="/dogs/1" />
            </Route>
            <Route path="/adopted">
              <Redirect to="/adopted/1" />
            </Route>
            <Route path="/articles/">
              <Redirect to="/articles/media" />
            </Route>

            {/* Default Route */}
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </main>
        <Footer />
      </div>
      <ToTopButton />
    </div>
  );
};

export default App;
