import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import FlexItemsContainer from '../../components/layout/FlexItemsContainer';
import Section from '../../components/layout/Section';
import SingleArticlePage from '../../components/dataViews/SingleArticlePage';

const Ranch = (): ReactElement => {
  return (
    <SingleArticlePage category="Ranch" title="Halfway Home Ranch">
      <Section>
        <FlexItemsContainer>
          <Link className="btn btn--large btn--primary btn--responsive-mx" to="/adopted/1">
            Adopted Dogs
          </Link>
        </FlexItemsContainer>
      </Section>
    </SingleArticlePage>
  );
};

export default Ranch;
