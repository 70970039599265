import { ReactElement } from 'react';

export const Section = ({ ...props }): ReactElement => {
  return (
    <section
      className={`max-w-md sm:max-w-5xl mx-auto px-2 sm:px-8 lg:px-16 py-4 sm:py-6 space-y-4 ${props.className}`}
    >
      {props.children}
    </section>
  );
};
