import { NetworkStatus, useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';

import { GET_POSTS } from '../../../api/queries';
import { IPost } from '../../../types/commonTypes';
import ErrorLoading from '../../alerts/ErrorLoading';
import Loading from '../../alerts/Loading';
import { Post } from './Post';

export const PostList = (): ReactElement => {
  const { loading, error, data, refetch, networkStatus } = useQuery(GET_POSTS, {
    variables: { page: 1 },
    notifyOnNetworkStatusChange: true,
  });
  const [posts, setPosts] = useState<IPost[]>([]);
  const [page, setPage] = useState(1);
  const [thereAreMorePosts, setThereAreMorePosts] = useState(true);

  useEffect(() => {
    if (
      (!loading && data?.postsByPage?.posts) ||
      networkStatus === NetworkStatus.refetch
    ) {
      setPosts((prev) => [...prev, ...data.postsByPage.posts]);
    }
    if (!loading && page >= data?.postsByPage?.pages) {
      setThereAreMorePosts(false);
    }
    //eslint-disable-next-line
  }, [loading, networkStatus]);

  useEffect(() => {
    if (!loading && page >= data?.postsByPage?.pages) {
      setThereAreMorePosts(false);
    }
    //eslint-disable-next-line
  }, [page]);

  const handleLoadMore = () => {
    if (thereAreMorePosts) {
      const newPage = page + 1;
      refetch({ page: newPage });
      setPage(newPage);
    }
  };

  return (
    <div style={{ minHeight: '30rem' }} className="w-full space-y-6">
      {posts.length > 0 ? (
        <>
          {(posts as IPost[]).map((post) => (
            <div key={post.id}>
              <Post
                id={post.id}
                blocks={post.blocks}
                date={post.date}
                updated={post.updated}
              />
            </div>
          ))}
          <div className="flex justify-center items-center">
            {thereAreMorePosts && !loading && !error && (
              <button
                onClick={handleLoadMore}
                className="btn btn--large btn--primary"
              >
                Load More
              </button>
            )}
            {!thereAreMorePosts && (
              <p className="text-gray-500 italic">
                This is the end of the feed.
              </p>
            )}
            {loading && <Loading />}
            {error?.message && <ErrorLoading />}
          </div>
        </>
      ) : (
        <div className="bg-gray-50 py-4 px-6 sm:py-12 sm:px-16 shadow-md rounded-md">
          <Loading />
        </div>
      )}
    </div>
  );
};
