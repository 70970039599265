import { gql } from '@apollo/client';

export const GET_ARTICLES_BY_CATEGORY = gql`
  query GetArticles($category: String!) {
    articlesByCategory(category: $category) {
      id
      category
      title
      date
      headerImgUrl
    }
  }
`;

export const GET_ARTICLES_BY_CATEGORY_FULL = gql`
  query GetArticles($category: String!) {
    articlesByCategory(category: $category) {
      id
      category
      title
      date
      blocks
    }
  }
`;

export const GET_ARTICLE_BY_ID = gql`
  query GetArticles($id: String!) {
    articleById(id: $id) {
      id
      category
      title
      date
      blocks
    }
  }
`;

export const GET_NEWSLETTERS = gql`
  query GetNewsletters {
    newsletters {
      id
      title
      pdfUrl
      date
    }
  }
`;

export const GET_SPONSOR_DOGS = gql`
  query GetSponsorDogs {
    sponsorDogs {
      id
      name
      imgUrl
      description
    }
  }
`;

export const GET_DOGS_BY_STATUS = gql`
  query GetDogsByStatus($status: String!, $page: Int!, $pageSize: Int!) {
    dogsByStatus(status: $status, page: $page, pageSize: $pageSize) {
      pages
      dogs {
        id
        url
        breeds {
          primary
          secondary
          mixed
          unknown
        }
        tags
        name
        description
        photoUrl
        status
      }
    }
  }
`;

export const GET_ADOPTED_DOGS_BY_NAME = gql`
  query GetDogsByName($name: String!) {
    dogsByName(name: $name) {
      id
      url
      breeds {
        primary
        secondary
        mixed
        unknown
      }
      tags
      name
      description
      photoUrl
      status
    }
  }
`;

export const GET_DOGS_BY_STATUS_PREVIEW = gql`
  query GetDogsByStatus($status: String!, $page: Int!, $pageSize: Int!) {
    dogsByStatus(status: $status, page: $page, pageSize: $pageSize) {
      dogs {
        name
        photoUrl
        url
      }
    }
  }
`;

export const GET_NEWSLETTER_BY_ID = gql`
  query GetNewsletterById($id: String!) {
    newsletterById(id: $id) {
      title
      pdfUrl
    }
  }
`;

export const GET_POSTS = gql`
  query PostsByPage($page: Int!) {
    postsByPage(page: $page) {
      pages
      posts {
        id
        date
        updated
        blocks
      }
    }
  }
`;
