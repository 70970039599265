import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';

import { formatPhone, validPhoneRegex } from '../../helpers';
import { SEND_FORM_VOLUNTEER } from '../../api/mutations';

import Section from '../layout/Section';
import Input from '../inputs';

import FormLayout from '../layout/FormLayout';
import { STATES } from '../../constants';
import { Link } from 'react-router-dom';

const Volunteer = (): ReactElement => {
  const [sendFormVolunteer] = useMutation(SEND_FORM_VOLUNTEER);
  const history = useHistory();
  const [canSubmit, setCanSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleSubmit = () => {
    setCanSubmit(false);
    setSubmitFailed(false);
    setIsSubmitting(true);
    const variables = {
      ...formik.values,
      moreInfo: formik.values.moreInfo.join(', '),
    };
    sendFormVolunteer({ variables })
      .then((res) => handleResponse(res))
      .catch((err) => handleResponse(err));
  };

  // handle form submission response, if successful, send to confirmation page
  const handleResponse = (res: any) => {
    const status = res.data?.sendFormVolunteer?.status;
    const formNumber = res.data?.sendFormVolunteer?.orderNumber;
    setIsSubmitting(false);
    if (status === 'success') {
      history.push(`/forms/volunteer/confirmation?formNumber=${formNumber}`);
    } else {
      setCanSubmit(true);
      setSubmitFailed(true);
      console.log('Form submission error');
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      moreInfo: [],
      experienceDogs: '',
      experienceOther: '',
    },
    validationSchema: yup.object({
      fullName: yup
        .string()
        .required('Required.')
        .max(64, 'Must be 1 - 64 characters.'),
      email: yup
        .string()
        .email('Must be a valid email address.')
        .max(64, 'Must be 1 - 64 characters.'),
      phone: yup
        .string()
        .matches(
          validPhoneRegex,
          'Must be valid phone number, e.g. (123) 456-7890'
        )
        .required('Required.'),
      address: yup
        .string()
        .required('Required.')
        .max(64, 'Must be 1 - 64 characters.'),
      city: yup
        .string()
        .required('Required.')
        .max(64, 'Must be 1 - 64 characters.'),
      state: yup.string().required('Required.'),
      zip: yup
        .string()
        .required('Required.')
        .min(5, 'Must be 5 characters.')
        .max(5, 'Must be 5 characters.'),
      experienceDogs: yup.string().required('Required.'),
    }),
    onSubmit: () => handleSubmit(),
  });

  const submitErrors = {
    required: () => {
      if (Object.keys(formik.errors).length > 0)
        return 'Please fix required fields.';
    },
    failed: () => {
      if (submitFailed)
        return 'Form submission failed. Please try again later or contact WAG.';
    },
  };

  // format phone number input, then update formik value
  const handlePhone = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = formatPhone(value) || '';
    formik.setFieldValue('phone', value);
  };

  // check for errors and enable submitting
  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formik.errors]);

  return (
    <Section>
      <h1>Volunteer Application</h1>
      <p>
        Please fill out this application if you would like to volunteer with
        WAG. If you would like more information about volunteering or about WAG,
        feel free to contact us.
      </p>
      <Link to="/contact" className="btn btn--large btn--primary mb-2">
        Contact Us
      </Link>
      <form onSubmit={formik.handleSubmit}>
        <br />
        <p className="text-red-800">* Required</p>
        {/* User's contact info */}
        <h2 className="mt-6 mb-0">Your Information</h2>
        <p className="mb-4 text-sm">
          If you choose to add an email, you will receive a copy of your
          application.
        </p>
        <Input.FormikInput
          required
          keyName="fullName"
          className="max-w-sm"
          label="Full Name:"
          placeholder="First Last"
          formik={formik}
          maxLength={64}
        />
        <Input.FormikInput
          required
          keyName="address"
          className="max-w-sm"
          label="Address:"
          placeholder="123 Address Rd"
          formik={formik}
          maxLength={64}
        />
        <Input.FormikInput
          required
          keyName="city"
          className="max-w-sm"
          label="City:"
          placeholder="City"
          formik={formik}
          maxLength={64}
        />
        <div className="flex max-w-sm justify-between">
          <Input.FormikInput
            required
            type="select"
            keyName="state"
            className="w-44"
            label="State:"
            formik={formik}
            options={STATES}
          />
          <Input.FormikInput
            required
            keyName="zip"
            className="w-44"
            label="ZIP:"
            placeholder="12345"
            formik={formik}
            maxLength={5}
          />
        </div>
        <Input.FormikInput
          required
          keyName="phone"
          className="max-w-sm"
          label="Phone #:"
          placeholder="(123) 456-7890"
          formik={formik}
          onChange={handlePhone}
          maxLength={14}
        />
        <Input.FormikInput
          keyName="email"
          className="max-w-sm"
          label="Email:"
          type="email"
          placeholder="your_email@email.com"
          formik={formik}
          maxLength={64}
        />
        <Input.FormikInput
          type="checkbox"
          keyName="moreInfo"
          className="max-w-sm"
          label="I would like more information about how I can help with:"
          formik={formik}
          options={[
            'Adoption Counselors',
            'Adoption Days',
            'Information Booths',
            'Events',
            'Fundraising',
            'Grant Writing',
            'Foster Homes',
            'Community Education',
            'Socializing/Training',
          ]}
        />
        <Input.FormikInput
          required
          type="textarea"
          keyName="experienceDogs"
          className="max-w-sm"
          label="My experience with dogs includes:"
          formik={formik}
        />
        <Input.FormikInput
          type="textarea"
          keyName="experienceOther"
          className="max-w-sm"
          label="Other skills & experience useful to WAG (gardening, office skills, media):"
          formik={formik}
        />
        <button
          className="btn btn--large btn--primary mb-2"
          disabled={Object.keys(formik.errors).length > 0 || !canSubmit}
          type="submit"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        <FormLayout.Error>
          {submitErrors.failed()}
          {submitErrors.failed() && <br />}
          {submitErrors.required()}
          {submitErrors.required() && <br />}
        </FormLayout.Error>
      </form>
    </Section>
  );
};

export default Volunteer;
