import { ReactElement } from 'react';

import DogList from '../../components/dataViews/DogList';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';

export const AdoptedDogs = (): ReactElement => {
  return (
    <Page>
      {/* <TopContainer>
        <Link to="/dogs" className="anchor">
          <FontAwesomeIcon className="text-xs" icon={faArrowLeft} /> Back
        </Link>
      </TopContainer> */}
      <Section>
        <h1 className="scroll-pagination">Adopted Dogs</h1>
        <DogList queryStatus="adopted" baseUrl="/adopted" enableSearch />
      </Section>
    </Page>
  );
};
