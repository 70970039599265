import { useQuery } from '@apollo/client';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { EditorViewer } from '../EditorViewer';
import ErrorLoading from '../../alerts/ErrorLoading';
import Loading from '../../alerts/Loading';
import Section from '../../layout/Section';

import { GET_ARTICLES_BY_CATEGORY_FULL } from '../../../api/queries';
import { ArticleCategory, ArticleType } from '../../../types/commonTypes';
import { parseArticle } from '../../../helpers';

interface SingleArticlePageProps {
  category: ArticleCategory;
  title: string;
  children?: ReactNode;
}

export const SingleArticlePage = (
  props: SingleArticlePageProps
): ReactElement => {
  const { data, loading, error } = useQuery(GET_ARTICLES_BY_CATEGORY_FULL, {
    variables: { category: props.category },
  });
  const [articleData, setArticleData] = useState<ArticleType>();

  // check if fetch data is good, parse stringified data, then set articleData
  useEffect(() => {
    if (data?.articlesByCategory?.length > 0) {
      const parsed = parseArticle(data.articlesByCategory[0]);
      if (parsed.valid) {
        setArticleData(parsed.article);
      }
    }
  }, [data]);

  return (
    <div>
      <Section>
        {articleData && (
          <>
            <div className="sm:mb-12">
              <h2 className="text-3xl mb-2 text-center font-bold">
                {props.title}
              </h2>
            </div>
            <EditorViewer data={articleData} />
          </>
        )}
        {loading && <Loading />}
        {error && <ErrorLoading />}
      </Section>
      {props.children}
    </div>
  );
};
