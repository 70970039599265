import { ReactElement } from 'react';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import Event from '../../components/sections/Event';

export const Events = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Events</h1>
        <Event.EventDetails />
        {/* Need new Calendar link */}
        {/* <div className='py-4'>
          <iframe
            title="WAG Calendar Events"
            src="https://calendar.google.com/calendar/embed?height=400&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=23mfeol17cg7v7dt7kamlidppk%40group.calendar.google.com&amp;color=%235F6B02&amp;ctz=America%2FLos_Angeles"
            style={{ borderWidth: '0' }}
            width="100%"
            height="400"
            scrolling="no"
          ></iframe>
        </div> */}
      </Section>
    </Page>
  );
};
