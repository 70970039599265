import { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Section from '../../components/layout/Section';
import Page from '../../components/layout/Page';
import FlexItemsContainer from '../../components/layout/FlexItemsContainer';
import CarouselSwiper from '../../components/layout/CarouselSwiper';
import Event from '../../components/sections/Event';

import { shuffle } from '../../helpers';
import { GET_DOGS_BY_STATUS_PREVIEW } from '../../api/queries';
import { Dog, Slide } from '../../types/commonTypes';

export const Home = (): ReactElement => {
  const history = useHistory();
  const { data, loading } = useQuery(GET_DOGS_BY_STATUS_PREVIEW, {
    variables: { status: 'active', page: 1, pageSize: 30 },
  });
  const [slides, setSlides] = useState<Slide[]>([]);

  // if Home renders by default due to invalid Route, fix URL
  useEffect(() => {
    history.push('/');
  }, [history]);

  // get dog images for carousel
  useEffect(() => {
    if (data?.dogsByStatus?.dogs) {
      setSlides(
        shuffle(
          data.dogsByStatus.dogs.map((dog: Dog) => {
            return { src: dog.photoUrl, text: dog.name, link: dog.url };
          })
        ) as Slide[]
      );
    }
  }, [data]);

  return (
    <Page>
      <Section>
        <h1>Welfare for Animals Guild - WAG</h1>
        <h2>Our Mission</h2>
        <p className="text-lg">
          The purpose of the Welfare for Animals Guild is to protect animals
          from neglect, abuse, and exploitation, to advocate for their interest
          and welfare, and to inspire awareness and compassion for the animals
          whose world we share.
        </p>
      </Section>
      <Event.EventHome />
      <Section>
        <h2>Spay and Neuter Clinics</h2>
        <p>
          Special efforts have been made to help pet overpopulation with
          WAG-sponsored spay/neuter clinics.
        </p>
        <a
          href=" https://firebasestorage.googleapis.com/v0/b/wagsequim-162ee.appspot.com/o/pdf%2FWinter%202023%20Nwsltr-WEB.pdf?alt=media&token=0527c9f0-82ce-43fe-9226-9ee9b81ec16f"
          target="_blank"
          rel="noreferrer"
          className="anchor"
        >
          Read more in our Winter 2023 Newsletter
        </a>
        <br />
        <Link to="/articles/media/657e1723b820a7b1e42b598a" className="anchor">
          Read the article <em>WAG & CVAR Hold Spay-Neuter Clinics</em>
        </Link>
        <br />
        <Link
          to="/donation/spay-neuter-clinic"
          className="btn btn--large btn--primary btn--responsive-mx"
        >
          Donate to Spay/Neuter Clinics
        </Link>
      </Section>
      <Section>
        <h2>Our Dogs</h2>
        <CarouselSwiper slides={slides} loading={loading} />
        <FlexItemsContainer styles="pb-4">
          <Link
            to="/dogs"
            className="btn btn--large btn--primary btn--responsive-mx"
          >
            Meet Our Dogs
          </Link>
          <a
            href="https://forms.gle/NRtYHLKCwQHWoxDL9"
            target="_blank"
            rel="noreferrer"
            className="btn btn--large btn--secondary btn--responsive-mx"
          >
            Apply To Adopt
          </a>
        </FlexItemsContainer>
        <p>
          Since 2001, WAG has placed dogs into new, loving homes. Our rescues
          come to us in many ways: owner surrenders due to unplanned life
          changes, over-populated shelters, and strays found wandering streets
          and fields. Prior to adoption, all of our rescues are spayed/neutered,
          receive all vaccines, are micro-chipped, and all other medical needs
          are addressed.
        </p>
        <p>
          In addition to other expenses, our veterinary bill averages over
          $50,000 annually. We do multiple fundraisers throughout the year to
          raise money to help meet our expenses. What sets us apart from other
          rescue groups is that the dogs either live in private homes or at the{' '}
          <Link
            className="underline text-red-800 hover:text-red-500"
            to="/articles/ranch"
          >
            Halfway Home Ranch
          </Link>{' '}
          until they are adopted. This way, we get to really know the dogs and
          are more able to find the perfect match for them. Our foster families
          care for the dogs right along with their own dogs. We pay for all
          their needs and they provide the love!
        </p>
      </Section>
    </Page>
  );
};
