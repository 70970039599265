import { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import Section from '../../layout/Section';
import Loading from '../../alerts/Loading';
import ErrorLoading from '../../alerts/ErrorLoading';
import { ArticlePreview } from './ArticlePreview';

import { GET_ARTICLES_BY_CATEGORY } from '../../../api/queries';
import { ArticleType, ArticleCategory } from '../../../types/commonTypes';
import { useLocation } from 'react-router';
import { filterBySchema, sortArticlesByDate } from '../../../helpers';

interface ArticleListProps {
  fetchCategory: ArticleCategory;
}

// fetched articles should match this
const fetchedArticleSchema = {
  id: 'string',
  title: 'string',
  date: 'string',
  category: 'string',
  headerImgUrl: 'string',
};

export const ArticleList = (props: ArticleListProps): ReactElement => {
  const { fetchCategory } = props;
  const { loading, error, data, refetch } = useQuery(GET_ARTICLES_BY_CATEGORY, {
    variables: { category: fetchCategory },
  });
  const location = useLocation();
  const [path, setPath] = useState('');
  const [sortedArticles, setSortedArticles] = useState<ArticleType[]>();

  // refetch articles when component loads
  useEffect(() => {
    refetch();
    // get the last URL segment for use in generating article links
    const pathArr = location.pathname.split('/');
    setPath(pathArr[pathArr.length - 1]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.articlesByCategory) {
      // sanitize fetched data by schema
      const [matchedArticles] = filterBySchema(
        data.articlesByCategory,
        fetchedArticleSchema
      );
      // sort articles by date
      setSortedArticles(sortArticlesByDate(matchedArticles as ArticleType[]));
    }
  }, [data]);

  return (
    <Section>
      {sortedArticles &&
        sortedArticles.map((article: ArticleType) => (
          <ArticlePreview
            key={article.id}
            title={article.title}
            date={article.date}
            imgUrl={article.headerImgUrl}
            articleId={article.id}
            category={path}
          />
        ))}
      {loading && <Loading />}
      {error && !data && <ErrorLoading />}
    </Section>
  );
};
