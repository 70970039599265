import { ReactElement } from 'react';
import EventDetails from './EventDetails';
import { EVENTS_ARE_RUNNING } from '../../../constants';
import Section from '../../layout/Section';

// event information box shown on Home page, edit as needed for current events
const EventHome = (): ReactElement => {
  if (EVENTS_ARE_RUNNING) {
    return (
      <Section>
        <h2>Events</h2>
        <EventDetails />
      </Section>
    );
  } else {
    return <></>;
  }
};

export default EventHome;
