import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import DogList from '../../components/dataViews/DogList';
import FlexItemsContainer from '../../components/layout/FlexItemsContainer';
import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';

export const Dogs = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Our Dogs</h1>
        <h2>Adoption Process</h2>
        <p>
          The first step in all our adoptions is to complete an adoption
          application (under <Link className="anchor" to="/forms">WAG Forms</Link>). Once the application is reviewed and
          approved, a meet and greet time will be scheduled. If the dog and the
          adopter are a good match, we finalize the adoption.
        </p>
        <p>
          We often get several applications for the same dog. Although many of
          the applicants could be a great home, we have to make the hard choice
          as to which "great home" would be the very best home for that
          particular dog.
        </p>
        <p>
          Our adoption fees vary depending on the dog. All our adult dogs are
          spayed/neutered, are up to date on vaccines, and micro chipped. All
          have had a medical health check-up, and any individual requirements
          will be stated in writing. Our puppies have all had a wellness vet
          visit, first vaccines, de-wormed and micro chipped.
        </p>
        <p>
          Veterinary care, feeding and grooming throughout the dog's life is a
          major expense of owning a dog. Please consider that when thinking
          about adopting a dog for life. Our thanks to the over 2000 families
          who have adopted one of our wonderful dogs and given them their very
          own Forever Home!
        </p>
        <FlexItemsContainer>
          <a
            href="https://forms.gle/NRtYHLKCwQHWoxDL9"
            target="_blank"
            rel="noreferrer"
            className="btn btn--large btn--primary btn--responsive-mx"
          >
            Apply to Adopt
          </a>
        </FlexItemsContainer>
      </Section>
      <Section>
        <h2 className="scroll-pagination">Dogs Available for Adoption</h2>
        <DogList queryStatus="active" baseUrl="/dogs" />
      </Section>
    </Page>
  );
};
