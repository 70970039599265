import { ReactElement } from 'react';
import Form from '../../components/forms';
import Page from '../../components/layout/Page';
import { EVENT_IS_RUNNING } from '../../constants';
import { Box } from '../../components/layout/Box/Box';
import { FlexItemsContainer } from '../../components/layout/FlexItemsContainer/FlexItemsContainer';
import { Link } from 'react-router-dom';
import { Section } from '../../components/layout/Section/Section';

const DogtoberfestFormPage = (): ReactElement => {
  return (
    <Page>
      {EVENT_IS_RUNNING.DOGFEST_REGISTRATION ? (
        <Form.Dogtoberfest />
      ) : (
        <Section>
          <Box>
            Registration is currently closed for DogtoberFest 2024.
          </Box>
          <FlexItemsContainer>
            <Link
              to="/home"
              className="btn btn--large btn--primary btn--responsive-mx"
            >
              Home
            </Link>
            <Link
              to="/contact"
              className="btn btn--large btn--secondary btn--responsive-mx"
            >
              Contact Us
            </Link>
          </FlexItemsContainer>
        </Section>
      )}
    </Page>
  );
};

export default DogtoberfestFormPage;
