import { ReactElement, ReactNode } from 'react';

export const FlexItemsContainer = (props: {
  children: ReactNode;
  styles?: string;
}): ReactElement => {
  return (
    <div
      className={`pt-4 flex flex-col sm:flex-row sm:justify-start sm:space-x-4 space-y-4 sm:space-y-0 ${props.styles}`}
    >
      {props.children}
    </div>
  );
};
