import { ReactElement } from 'react';

import loader from '../../../images/loader.svg';

export const Loading = (): ReactElement => {
  return (
    <div className="text-center w-full h-72 flex items-center">
      <img className="mx-auto" src={loader} alt="loading" />
    </div>
  );
};
