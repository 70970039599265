import { useQuery } from '@apollo/client';
import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ErrorLoading from '../../alerts/ErrorLoading';
import Loading from '../../alerts/Loading';
import PageSelector from '../../buttons/PageSelector';
import { DogCard } from './DogCard';

import { filterBySchema } from '../../../helpers';
import { GET_ADOPTED_DOGS_BY_NAME, GET_DOGS_BY_STATUS } from '../../../api/queries';
import { Dog } from '../../../types/commonTypes';

interface DogListProps {
  queryStatus: string;
  baseUrl: string;
  enableSearch?: boolean;
}

const dogSchema = {
  id: 'string',
  url: 'string',
  breeds: 'object',
  tags: 'array',
  name: 'string',
  description: 'string',
  photoUrl: 'string',
  status: 'string',
};

export const DogList = (props: DogListProps): ReactElement => {
  const { queryStatus, baseUrl, enableSearch } = props;
  const { page } = useParams<{ page: string }>();
  const history = useHistory();
  const [queryPage, setQueryPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { loading, error, data, refetch } = useQuery(GET_DOGS_BY_STATUS, {
    variables: { status: queryStatus, page: queryPage, pageSize: 12 },
  });
  const [searchInput, setSearchInput] = useState('');
  const [searching, setSearching] = useState(false);
  const dogsByName = useQuery(GET_ADOPTED_DOGS_BY_NAME, {
    variables: { name: '' },
  });
  const [dogs, setDogs] = useState<Dog[]>();
  const [searchError, setSearchError] = useState('');

  const fixUrl = () => history.push(`${baseUrl}/1`);

  // on query success, sanitize data against schema and set state
  useEffect(() => {
    // if enableSearch and user has entered a search query
    if (enableSearch && searching && dogsByName.data?.dogsByName) {
      const [matched] = filterBySchema(dogsByName.data.dogsByName, dogSchema);
      setDogs(matched as Dog[]);
    } else {
      // else, get dogs by page number
      if (data?.dogsByStatus?.pages) {
        setPages(data.dogsByStatus.pages);
      }
      if (data?.dogsByStatus?.dogs) {
        const [matched] = filterBySchema(data.dogsByStatus.dogs, dogSchema);
        // if there are dogs, render them
        if (matched?.length > 0) {
          setDogs(matched as Dog[]);
        } else {
          // if there aren't dogs and the page isn't 1, query page 1
          if (queryPage !== 1) {
            fixUrl();
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data, dogsByName.data, searching]);

  // check page param to query dogs page or fix URL
  useEffect(() => {
    // if page param can be coerced to number
    if (!isNaN(Number(page))) {
      // use page param to query dogs page
      setQueryPage(Number(page));
    } else {
      // push to page 1
      fixUrl();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    refetch({ page: queryPage });
    // eslint-disable-next-line
  }, [queryPage]);

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    if (searchInput.trim().length > 0 && searchInput.trim().length < 2) {
      setSearchError('Search query must be at least 2 characters');
    } else {
      setSearchError('');
    }
    if (searchInput.trim() === '' || searchInput.trim().length < 2) {
      setSearching(false);
    } else {
      setSearching(true);
      dogsByName.refetch({ name: searchInput });
    }
  };

  const handleClear = (e: FormEvent) => {
    e.preventDefault();
    setSearching(false);
    setSearchInput('');
    setSearchError('');
  };

  return (
    <>
      <div className="flex justify-between items-center flex-col lg:flex-row">
        {enableSearch && (
          <form
            onSubmit={handleSearch}
            className="space-x-2 lg:mr-0 mr-auto sm:mb-0 mb-8 relative"
          >
            <input
              className="border-4 px-4 rounded-full border-gray-600 sm:mb-0 mb-2"
              placeholder="Dog Name"
              value={searchInput}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(e.target.value);
              }}
            />
            <button className="btn btn--round btn--primary">
              <FontAwesomeIcon icon={faSearch as any} />
            </button>
            <button
              onClick={handleClear}
              className="btn btn--large btn--secondary"
            >
              Clear
            </button>
            {searchError !== '' && (
              <p className="absolute left-0 top-12 text-sm text-red-800">
                {searchError}
              </p>
            )}
          </form>
        )}
        {/* If loading or an error, hide PageSelector but allow it to stay mounted */}
        <div
          className={
            'ml-auto ' + (loading || error || searching ? 'hidden' : '')
          }
        >
          <PageSelector
            page={queryPage}
            pages={pages}
            baseUrl={baseUrl}
            scroller
          />
        </div>
      </div>
      {/* Dog cards */}
      {dogs && !loading && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 justify-center">
          {dogs.map((dog) => (
            <DogCard key={dog.id} dog={dog} />
          ))}
        </div>
      )}
      {searching && dogs?.length === 0 && (
        <p>Sorry, no dogs matched the search query.</p>
      )}
      <div className={loading || error || searching ? 'hidden' : ''}>
        <PageSelector
          page={queryPage}
          pages={pages}
          baseUrl={baseUrl}
          scroller
        />
      </div>
      {loading && <Loading />}
      {error && <ErrorLoading />}
    </>
  );
};
