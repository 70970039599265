import { Slide } from '../../types/commonTypes';
import volunteer1 from './volunteer1.png';
import volunteer2 from './volunteer2.png';
import volunteer3 from './volunteer3.png';
import volunteer4 from './volunteer4.png';
import volunteer5 from './volunteer5.png';
import volunteer6 from './volunteer6.png';
import volunteer7 from './volunteer7.png';
import volunteer8 from './volunteer8.png';
import volunteer9 from './volunteer9.png';
import volunteer10 from './volunteer10.png';
import volunteer11 from './volunteer11.png';
import volunteer12 from './volunteer12.png';
import volunteer13 from './volunteer13.png';
import volunteer14 from './volunteer14.png';
import volunteer15 from './volunteer15.png';
import volunteer16 from './volunteer16.png';
import volunteer17 from './volunteer17.png';
import volunteer18 from './volunteer18.png';
import volunteer19 from './volunteer19.png';
import volunteer20 from './volunteer20.png';
import volunteer21 from './volunteer21.png';
import volunteer22 from './volunteer22.png';
import volunteer23 from './volunteer23.png';
import volunteer24 from './volunteer24.png';
import volunteer25 from './volunteer25.png';
import volunteer26 from './volunteer26.png';
import volunteer27 from './volunteer27.png';
import volunteer28 from './volunteer28.png';
import volunteer29 from './volunteer29.png';

const volunteerSlides: Slide[] = [
  { src: volunteer1 },
  { src: volunteer2 },
  { src: volunteer3 },
  { src: volunteer4 },
  { src: volunteer5 },
  { src: volunteer6 },
  { src: volunteer7 },
  { src: volunteer8 },
  { src: volunteer9 },
  { src: volunteer10 },
  { src: volunteer11 },
  { src: volunteer12 },
  { src: volunteer13 },
  { src: volunteer14 },
  { src: volunteer15 },
  { src: volunteer16 },
  { src: volunteer17 },
  { src: volunteer18 },
  { src: volunteer19 },
  { src: volunteer20 },
  { src: volunteer21 },
  { src: volunteer22 },
  { src: volunteer23 },
  { src: volunteer24 },
  { src: volunteer25 },
  { src: volunteer26 },
  { src: volunteer27 },
  { src: volunteer28 },
  { src: volunteer29 },
];

export default volunteerSlides;
