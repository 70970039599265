import { ReactElement } from 'react';
import Form from '../../components/forms';
import Page from '../../components/layout/Page';

const VolunteerFormPage = (): ReactElement => {
  return (
    <Page>
      <Form.Volunteer />
    </Page>
  );
};

export default VolunteerFormPage;
