import { ReactElement } from 'react';

import PayPalButton from '../../buttons/PayPalButton';
import FlexItemsContainer from '../../layout/FlexItemsContainer';

import igIcon from '../../../images/instagram_icon.png';
import fbIcon from '../../../images/facebook_icon.png';
import ytIcon from '../../../images/youtube_icon.png';
import ttIcon from '../../../images/tiktok_icon.png';

const style = {
  h2: 'text-xl mb-4 text-center font-medium',
};

export const Footer = (): ReactElement => {
  return (
    <footer className="footer text-center bg-gray-200 py-6 px-2">
      <div className="flex justify-center">
        <FlexItemsContainer styles="sm:space-x-14">
          <div>
            <h2 className={`${style.h2} mt-4`}>Donate Via PayPal</h2>
            <PayPalButton />
          </div>
          <div>
            <h2 className={`${style.h2} mt-4`}>Donate Via Amazon</h2>
            <a
              href="https://www.amazon.com/hz/wishlist/ls/2MDUEAATLHMWB?ref_=wl_share"
              rel="noreferrer"
              target="_blank"
              className="anchor-dark mb-2 sm:my-0"
            >
              Amazon Wishlist
            </a>
          </div>
        </FlexItemsContainer>
      </div>
      <h2 className={`${style.h2} mt-6`}>Contact Us</h2>
      <address className="mx-auto mb-4 sm:max-w-md sm:text-left flex flex-col align-center sm:inline-block not-italic">
        <div className="sm:w-28 inline-block">
          <div className="font-medium">Mail:</div>
        </div>
        <span className="mb-2 sm:my-0">P.O. BOX 3966, Sequim, WA 98382</span>
        <br className="hidden sm:inline" />
        <div className="sm:w-28 inline-block">
          <div className="font-medium">Phone:</div>
        </div>
        <span className="mb-2 sm:my-0">(360) 460-6258</span>
        <br className="hidden sm:inline" />
        <div className="sm:w-28 inline-block">
          <div className="font-medium">Ranch Email:</div>
        </div>
        <a
          href="mailto:wagranch@yahoo.com"
          className="anchor-dark mb-2 sm:my-0"
        >
          wagranch@yahoo.com
        </a>
        <br className="hidden sm:inline" />
        <div className="sm:w-28 inline-block">
          <div className="font-medium">Tax ID #:</div>
        </div>
        <span className="mb-2 sm:my-0">91-2132797</span>
      </address>
      {/* Social links */}
      <h2 className={`${style.h2} mt-6`}>Please check out our social media by clicking on the buttons below</h2>
      <nav className="my-2">
        <ul className="flex justify-center my-4 space-x-2">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/wagwadog/"
            >
              <img className="w-10" src={igIcon} alt="Instagram" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/wagsequim/"
            >
              <img className="w-10" src={fbIcon} alt="Facebook" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCWkYKbSw57BXatnwqU5jFDA"
            >
              <img className="w-10" src={ytIcon} alt="YouTube" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@wagwadogmel?"
            >
              <img className="w-10" src={ttIcon} alt="TikTok" />
            </a>
          </li>
        </ul>
      </nav>
      <p className="text-sm">
        &copy; Welfare for Animals Guild (WAG) 2001 - {new Date().getFullYear()}
        . All rights reserved.
      </p>
    </footer>
  );
};
