import { ReactElement, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { ArticlePreview } from '../ArticleList/ArticlePreview';
import Section from '../../layout/Section';
import Loading from '../../alerts/Loading';
import ErrorLoading from '../../alerts/ErrorLoading';

import { GET_NEWSLETTERS } from '../../../api/queries';
import { ArticleType } from '../../../types/commonTypes';
import { useLocation } from 'react-router';
import { filterBySchema, sortArticlesByDate } from '../../../helpers';
import newsletterImg from '../../../images/newsletter_cover.png';

// fetched articles should match this
const fetchedArticleSchema = {
  id: 'string',
  title: 'string',
  date: 'string',
  pdfUrl: 'string',
};

export const NewsletterList = (): ReactElement => {
  const { loading, error, data, refetch } = useQuery(GET_NEWSLETTERS);
  const location = useLocation();
  const [path, setPath] = useState('');
  const [sortedNewsletters, setSortedNewsletters] = useState<ArticleType[]>();

  // refetch articles when component loads
  useEffect(() => {
    refetch();
    // get the last URL segment for use in generating article links
    const pathArr = location.pathname.split('/');
    setPath(pathArr[pathArr.length - 1]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.newsletters) {
      // sanitize fetched data by schema
      const [matchedNewsletters] = filterBySchema(
        data.newsletters,
        fetchedArticleSchema
      );
      // sort articles by date
      setSortedNewsletters(
        sortArticlesByDate(matchedNewsletters as ArticleType[])
      );
    }
  }, [data]);

  return (
    <Section>
      {sortedNewsletters &&
        sortedNewsletters.map((newsletter: ArticleType) => (
          <ArticlePreview
            key={newsletter.id}
            title={newsletter.title}
            date={newsletter.date}
            imgUrl={newsletterImg}
            articleId={newsletter.id}
            category={path}
            hideDate
          />
        ))}
      {loading && <Loading />}
      {error && !data && <ErrorLoading />}
    </Section>
  );
};
