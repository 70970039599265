import { ReactElement } from 'react';

import { PAYPAL_URL } from '../../../constants';
import payPal from '../../../images/PayPalButton.png';

export const PayPalButton = (): ReactElement => {
  return (
    <div className='max-w-sm'>
      <a
        className="inline-block"
        href={PAYPAL_URL}
        target="_blank"
        rel="noreferrer"
      >
        <img className="mx-auto" src={payPal} alt="PayPal"></img>
      </a>
      <p className='text-xs text-left'>
        Adoption fees, grants, and donations are essential to WAG's success.
        Please note that WAG makes every effort to apply donations as designated
        to a specific cause but reserves the right to use them where most
        needed.
      </p>
    </div>
  );
};
