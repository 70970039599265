import { ReactElement } from 'react';

import Page from '../../components/layout/Page';
import Section from '../../components/layout/Section';
import PayPalButton from '../../components/buttons/PayPalButton';

const Clinic = (): ReactElement => {
  return (
    <Page>
      <Section>
        <h1>Spay and Neuter Clinic Donation</h1>
        <p>
          WAG greatly appreciates your donations. If you would like your
          donation to fund the WAG-sponsored spay/neuter clinics, follow the
          PayPal donation link below.
        </p>
        <p>Click "Add special instructions to the seller."</p>
        <p>Enter the instructions: "For spay and neuter clinics."</p>
        <PayPalButton />
      </Section>
    </Page>
  );
};

export default Clinic;
