import { ReactElement } from 'react';

import dogFestLogo from '../../../images/DogtoberFestLogo.png';
import { FlexItemsContainer } from '../../layout/FlexItemsContainer/FlexItemsContainer';
import { Link } from 'react-router-dom';
import { EVENTS_ARE_RUNNING, EVENT_IS_RUNNING } from '../../../constants';
import Box from '../../layout/Box';

// event information shown on Events page, edit as needed for current events
const EventDetails = (): ReactElement => {
  return (
    <>
      {EVENTS_ARE_RUNNING ? (
        <>
          {/* DogtoberFest */}
          {(EVENT_IS_RUNNING.DOGFEST_EVENT) && (
            <>
              <h3>
                <div className="md:w-3/4 w-full">
                  <img src={dogFestLogo} alt="DogtoberFest" />
                </div>
              </h3>
              <p className="text-lg font-medium text-red-800">
                We are happy to announce our 7th annual DogtoberFest on{' '}
                Saturday, Oct. 19th at the Guy Cole Center in Sequim, 5:00pm -
                9:00pm!
              </p>
              <p>
                This fun evening will return with its fantastic dinner prepared
                by Tedescos Italian Fresh, our famous specialty cocktails,
                fabulous silent auction baskets, as well as delicious homemade
                desserts and gourmet cookies made by our amazing volunteers.
                This evening is a chance to be with your dog-loving community
                raising funds to continue WAG's work of rescuing and
                rehabilitating dogs on their way to their forever homes. And you
                never know&mdash;there could be a few surprises!
              </p>
              <p>
                This year our "Raise the Paw" segment of the evening will
                support our emergency medical issues. As you know by reading our
                newsletters, WAG gets called much too often to provide emergency
                surgeries and after-care for surrendered and abandoned dogs.
                Stories like Casey's, Spirit's, and Theodore's are sadly too
                common.
              </p>
              <p className="text-lg font-medium text-red-800">
                As of July, WAG's medical expenses have already exceeded
                $60,000.
              </p>
              <p>
                This year's event is limited to 160 people, so make your
                reservation early. Individual tickets are $85.00 each, or
                reserve a table for eight for $800, which includes two bottles
                of wine and a special “party favor” for each member of your
                group.
              </p>
              <p>
                You can also reserve a bottle (or bottles) of wine with your
                reservation. Choose from Pinot Grigio 2020 or Merlot 2020 for
                $20 each.
              </p>
              <p>
                Reservations can be made on our website from the link below or
                by mailing the form from our Summer 2024 newsletter. We accept
                checks, PayPal, and credit card. All reservations must be
                received by September 27th.
              </p>
              <p>
                We hope to see you there and if you can't join us, please
                consider a donation to support WAG's mission to find happy
                endings for all the dogs!
              </p>
              {EVENT_IS_RUNNING.DOGFEST_REGISTRATION ? (
                // Registration ON
                <>
                  <FlexItemsContainer styles="pb-6">
                    <Link
                      to="/forms/dogtoberfest"
                      className="btn btn--large btn--primary btn--responsive-mx"
                    >
                      Make a Reservation
                    </Link>
                    <Link
                      to="/contact"
                      className="btn btn--large btn--secondary btn--responsive-mx"
                    >
                      Contact Us
                    </Link>
                  </FlexItemsContainer>
                </>
              ) : (
                // Registration OFF
                <>
                  <Box theme="red">
                    <p className="text-xl">
                      DogtoberFest tickets are sold out. Thank you for your
                      support!
                    </p>
                  </Box>
                  <FlexItemsContainer styles="pb-6">
                    <button className="btn btn--large btn--primary" disabled>
                      Registration Closed
                    </button>
                    <Link
                      to="/contact"
                      className="btn btn--large btn--secondary btn--responsive-mx"
                    >
                      Contact Us
                    </Link>
                  </FlexItemsContainer>
                </>
              )}
            </>
          )}
          {/* end DogtoberFest */}
          {/* WAG Open House */}
          {EVENT_IS_RUNNING.OPEN_HOUSE && (
            <>
              <h3>Aug 10 - Open House at the Halfway Home Ranch</h3>
              <div className="flex justify-center">
                <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/wagsequim-162ee.appspot.com/o/test%2F4tx5JdZL82HJi3OL.jpeg?alt=media&token=1fbebb2f-a2c4-4f43-bae3-71bd9d304d5f'
                  }
                  alt="open house"
                />
              </div>
              <p>
                Join us for our open house on August 10th, 11 a.m - 1 p.m., but
                kindly leave your canine companions at home. Please wear closed
                toe shoes, as we ask that all visitors step in a sanitizing
                solution before entering the Halfway Home Ranch.
              </p>
            </>
          )}
          {/* Winery */}
          {EVENT_IS_RUNNING.WINERY && (
            <>
              <h3>Aug 17 - Olympic Cellars Benefit Concert</h3>
              <div className="flex justify-center">
                <img
                  src={
                    'https://firebasestorage.googleapis.com/v0/b/wagsequim-162ee.appspot.com/o/test%2FvtOx7u6qrTXPt7lZ.jpeg?alt=media&token=a7379cfd-c121-44a6-85d6-f9018f2aa869'
                  }
                  alt="music band standing against wall"
                />
              </div>
              <p>
                We're excited to announce that we will be partnering with
                Olympic Cellars Winery again this year as part of their Summer
                Benefit Concert Series! On August 17th, the band EDEN will be
                performing to support WAG.
              </p>
              <p>
                EDEN is a rock/blues/pop band from the Pacific Northwest,
                fronted by the powerhouse vocals of Savanna Woods, a recent Top
                20 artist on NBC’s The Voice. Savanna’s unique voice is paired
                with these incredibly talented musicians to create an impactful
                and energetic sound: Aaron Hiebert on electric guitar, Jason
                Edwards on drums, and Ethan Gibbons on bass. They play a dynamic
                range of original music, paired with covers from the 60’s
                through today. From Fleetwood Mac, to AC/DC, to Stevie Ray
                Vaughan, they are guaranteed to get you grooving!
              </p>
              <p>
                Tickets can be purchased through Olympic Cellars Winery's
                website.
              </p>
              <p>
                <a
                  className="anchor"
                  href="https://olympiccellars.com/event/saturday-august-17-2024/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://olympiccellars.com/event/saturday-august-17-2024/
                </a>
              </p>
            </>
          )}
          {EVENT_IS_RUNNING.DOGFEST_SAVE && (
            <>
              <h3>October 19 - DogtoberFest 2024</h3>
              <p>
                Save the date! DogtoberFest 2024 will be held this year on
                October 19th. Ticket sales will begin the first week of August.
              </p>
            </>
          )}
        </>
      ) : (
        <p>There are no events currently running. Please check back later!</p>
      )}
    </>
  );
};

export default EventDetails;
