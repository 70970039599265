import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';

import { sanitizeDollars, formatPhone, validPhoneRegex } from '../../helpers';
import { SEND_FORM_DOGTOBERFEST } from '../../api/mutations';

import Section from '../layout/Section';
import Input from '../inputs';
import OrderTotal from '../layout/OrderTotal';
import Box from '../layout/Box';
import { Link } from 'react-router-dom';
import FormLayout from '../layout/FormLayout';

const PRICES = {
  TICKET: 85,
  TABLE: 800,
  WINE: 20,
};

type FormValues =
  | 'fullName'
  | 'email'
  | 'phone'
  | 'tickets'
  | 'tables'
  | 'ticketName1'
  | 'ticketName2'
  | 'ticketName3'
  | 'ticketName4'
  | 'ticketName5'
  | 'ticketName6'
  | 'ticketName7'
  | 'ticketName8'
  | 'tableName1'
  | 'tableName2'
  | 'tableName3'
  | 'tableName4'
  | 'tableName5'
  | 'tableName6'
  | 'tableName7'
  | 'tableName8'
  | 'winePinotGrigio'
  | 'wineMerlot'
  | 'donationSelection'
  | 'donationCustom';

// returns a yup schema to make reservation name inputs required up to the number of tickets being purchased
const reservationInputSchema = (name: any, minValue: number) => {
  return yup
    .string()
    .max(64, 'Must be 1 - 64 characters.')
    .when(name, (value, schema) => {
      if (Number(value) >= minValue) {
        return schema.required('Required.');
      } else {
        return schema;
      }
    });
};

const Dogtoberfest = (): ReactElement => {
  const [totals, setTotals] = useState({
    total: 0,
    donation: 0,
    tickets: 0,
    tables: 0,
    winePinot: 0,
    wineMerlot: 0,
  });
  const [sendFormDogtoberfest] = useMutation(SEND_FORM_DOGTOBERFEST);
  const history = useHistory();
  const [canSubmit, setCanSubmit] = useState(true);
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleSubmit = () => {
    setCanSubmit(false);
    setSubmitFailed(false);
    sendFormDogtoberfest({
      variables: {
        fullName: formik.values.fullName,
        email: formik.values.email,
        phone: formik.values.phone,
        tickets: Number(formik.values.tickets),
        tables: Number(formik.values.tables),
        ticketName1: formik.values.ticketName1,
        ticketName2: formik.values.ticketName2,
        ticketName3: formik.values.ticketName3,
        ticketName4: formik.values.ticketName4,
        ticketName5: formik.values.ticketName5,
        ticketName6: formik.values.ticketName6,
        ticketName7: formik.values.ticketName7,
        ticketName8: formik.values.ticketName8,
        tableName1: formik.values.tableName1,
        tableName2: formik.values.tableName2,
        tableName3: formik.values.tableName3,
        tableName4: formik.values.tableName4,
        tableName5: formik.values.tableName5,
        tableName6: formik.values.tableName6,
        tableName7: formik.values.tableName7,
        tableName8: formik.values.tableName8,
        winePinotGrigio: Number(formik.values.winePinotGrigio),
        wineMerlot: Number(formik.values.wineMerlot),
        donation: totals.donation,
        total: totals.total,
      },
    })
      .then((res) => handleResponse(res))
      .catch((err) => handleResponse(err));
  };

  // handle form submission response, if successful, send to confirmation page
  const handleResponse = (res: any) => {
    const status = res.data?.sendFormDogtoberfest?.status;
    const orderNumber = res.data?.sendFormDogtoberfest?.orderNumber;

    if (status === 'success') {
      history.push(
        `/forms/dogtoberfest/confirmation?orderNumber=${orderNumber}&total=${totals.total}`
      );
    } else {
      setCanSubmit(true);
      setSubmitFailed(true);
      console.log('Form submission error');
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      tickets: '0',
      tables: '0',
      ticketName1: '',
      ticketName2: '',
      ticketName3: '',
      ticketName4: '',
      ticketName5: '',
      ticketName6: '',
      ticketName7: '',
      ticketName8: '',
      tableName1: '',
      tableName2: '',
      tableName3: '',
      tableName4: '',
      tableName5: '',
      tableName6: '',
      tableName7: '',
      tableName8: '',
      winePinotGrigio: '0',
      wineMerlot: '0',
      donationSelection: '0',
      donationCustom: '0',
    },
    validationSchema: yup.object({
      fullName: yup
        .string()
        .required('Required.')
        .max(64, 'Must be 1 - 64 characters.'),
      email: yup
        .string()
        .email('Must be a valid email address.')
        .max(64, 'Must be 1 - 64 characters.'),
      phone: yup
        .string()
        .matches(
          validPhoneRegex,
          'Must be valid phone number, e.g. (123) 456-7890'
        )
        .required('Required.'),
      ticketName1: reservationInputSchema('tickets', 1),
      ticketName2: reservationInputSchema('tickets', 2),
      ticketName3: reservationInputSchema('tickets', 3),
      ticketName4: reservationInputSchema('tickets', 4),
      ticketName5: reservationInputSchema('tickets', 5),
      ticketName6: reservationInputSchema('tickets', 6),
      ticketName7: reservationInputSchema('tickets', 7),
      ticketName8: reservationInputSchema('tickets', 8),
      tableName1: reservationInputSchema('tables', 1),
      tableName2: reservationInputSchema('tables', 1),
      tableName3: reservationInputSchema('tables', 1),
      tableName4: reservationInputSchema('tables', 1),
      tableName5: reservationInputSchema('tables', 1),
      tableName6: reservationInputSchema('tables', 1),
      tableName7: reservationInputSchema('tables', 1),
      tableName8: reservationInputSchema('tables', 1),
    }),
    onSubmit: () => handleSubmit(),
  });

  const submitErrors = {
    required: () => {
      if (Object.keys(formik.errors).length > 0)
        return 'Please fix required fields.';
    },
    donation: () => {
      if (totals.total <= 0) return 'Please choose a donation.';
    },
    failed: () => {
      if (submitFailed)
        return 'Form submission failed. Please try again later or contact WAG.';
    },
  };

  // format phone number input, then update formik value
  const handlePhone = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = formatPhone(value) || '';
    formik.setFieldValue('phone', value);
  };

  // sanitize custom donation amount
  const handleCustomDonation = (e: ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);
    value = sanitizeDollars(value);
    formik.setFieldValue('donationCustom', value);
    setTotals({ ...totals, donation: Number(value) });
  };

  // calculate total donation costs
  useEffect(() => {
    // calculate additional donation
    let custom = formik.values.donationCustom;
    let selection = formik.values.donationSelection;
    let donation = 0;
    // choose either the custom input field or selection field for donation amount
    if (selection === 'other') {
      donation = Number(custom);
    } else {
      donation = Number(selection);
      // if a selection is chosen, zero the custom input
      formik.setFieldValue('donationCustom', 0);
    }

    // calculate totals
    const ticketTotal = Number(formik.values.tickets) * PRICES.TICKET;
    const tableTotal = Number(formik.values.tables) * PRICES.TABLE;
    const winePinotTotal = Number(formik.values.winePinotGrigio) * PRICES.WINE;
    const wineMerlotTotal = Number(formik.values.wineMerlot) * PRICES.WINE;
    const total =
      ticketTotal + tableTotal + winePinotTotal + wineMerlotTotal + donation;

    // update totals in state
    setTotals({
      total,
      donation,
      tickets: ticketTotal,
      tables: tableTotal,
      winePinot: winePinotTotal,
      wineMerlot: wineMerlotTotal,
    });
    // eslint-disable-next-line
  }, [formik.values]);

  return (
    <Section>
      <h1>DogtoberFest 2024 Registration</h1>
      <p>
        For information about the event, check our{' '}
        <Link to="/events" className="anchor">
          Events page
        </Link>
        .
      </p>
      <p>
        For any event questions, call us at (360) 460-6258 or email us at
        djbdfest@gmail.com.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <br />
        <p className="text-red-800">* Required</p>
        {/* User's contact info */}
        <h2 className="mt-6 mb-0">Your Information</h2>
        <p className="mb-4 text-sm">
          If you choose to add an email, you will receive a copy of your
          registration form.
        </p>
        <Input.FormikInput
          required
          keyName="fullName"
          className="max-w-sm"
          label="Full Name:"
          placeholder="First Last"
          formik={formik}
        />
        <Input.FormikInput
          required
          keyName="phone"
          className="max-w-sm"
          label="Phone #:"
          placeholder="(123) 456-7890"
          formik={formik}
          onChange={handlePhone}
        />
        <Input.FormikInput
          keyName="email"
          className="max-w-sm"
          label="Email:"
          type="email"
          placeholder="your_email@email.com"
          formik={formik}
        />
        {/* Ticket/table purchases */}
        <h2 className="mt-6 mb-0">Tickets & Tables</h2>
        <p className="mb-4 text-sm">
          Choose the number of individuals you are registering for, either
          individual tickets or a table for 8. A table will include two bottles
          of wine and a special "party favor" for each member of your group.
        </p>
        <div className="flex flex-wrap">
          <Input.FormikInput
            keyName="tickets"
            className="w-40 mr-6"
            label="Tickets @ $85 each:"
            type="select"
            formik={formik}
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
          />
          <Input.FormikInput
            keyName="tables"
            className="w-40"
            label="Table for 8 @ $800:"
            type="select"
            formik={formik}
            options={[
              { text: 'No', value: '0' },
              { text: 'Yes', value: '1' },
            ]}
          />
        </div>
        {/* Ticket reservation names */}
        {Number(formik.values.tickets) > 0 && (
          <>
            <h2 className="mt-6 mb-0">
              Full names for each ticket reservation
            </h2>
            <p className="mb-4 text-sm">
              If you are attending, please include your own name for either a
              ticket or table reservation.
            </p>
            {new Array(Number(formik.values.tickets)).fill('').map((val, i) => {
              const key = i + 1;
              const keyName = `ticketName${key}` as FormValues;
              return (
                <Input.FormikInput
                  keyName={keyName}
                  className="max-w-sm"
                  label={`Ticket Reservation ${key} Full Name:`}
                  placeholder="First Last"
                  required
                  formik={formik}
                  key={keyName}
                />
              );
            })}
          </>
        )}
        {/* Table reservation names */}
        {Number(formik.values.tables) > 0 && (
          <>
            <h2 className="mt-6 mb-0">Full names for table of 8 reservation</h2>
            <p className="mb-4 text-sm">
              If you are attending, please include your own name for either a
              ticket or table reservation.
            </p>
            {new Array(8).fill('').map((val, i) => {
              const key = i + 1;
              const keyName = `tableName${key}` as FormValues;
              return (
                <Input.FormikInput
                  keyName={keyName}
                  className="max-w-sm"
                  label={`Table Reservation ${key} Full Name:`}
                  placeholder="First Last"
                  required
                  formik={formik}
                  key={keyName}
                />
              );
            })}
          </>
        )}
        {/* Wine */}
        <h2 className="mt-6 mb-0">Wine</h2>
        <p className="mb-4 text-sm">Preorder bottles of wine:</p>
        <div className="flex flex-wrap">
          <Input.FormikInput
            keyName="winePinotGrigio"
            className="w-40 mr-6"
            label="Bottles of Pinot Grigio"
            type="select"
            formik={formik}
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
          />
          <Input.FormikInput
            keyName="wineMerlot"
            className="w-40"
            label="Bottles of Merlot"
            type="select"
            formik={formik}
            options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
          />
        </div>
        {/* Extra Donation */}
        <h2 className="mt-6 mb-0">Additional Donation</h2>
        <p className="mb-4 text-sm">
          I can't make it to DogtoberFest, but here is my donation towards WAG's
          work:
        </p>
        <Input.FormikInput
          keyName="donationSelection"
          className="w-40 mr-6"
          label="Donation Amount:"
          type="select"
          formik={formik}
          options={[
            { text: '$ 0', value: '0' },
            { text: '$ 25', value: '25' },
            { text: '$ 50', value: '50' },
            { text: '$ 100', value: '100' },
            { text: '$ 250', value: '250' },
            { text: '$ 500', value: '500' },
            { text: '$ 1000', value: '1000' },
            { text: 'Other', value: 'other' },
          ]}
        />
        {formik.values.donationSelection === 'other' && (
          <Input.FormikInput
            keyName="donationCustom"
            className="max-w-sm"
            label="Custom Amount:"
            placeholder="123"
            type="dollar"
            formik={formik}
            onChange={handleCustomDonation}
          />
        )}
        {/* Cart & total */}
        <h2 className="mt-6 mb-2">Donation Summary</h2>
        <OrderTotal
          total={totals.total}
          order={[
            {
              item: 'Tickets',
              amount: Number(formik.values.tickets),
              cost: totals.tickets,
            },
            {
              item: 'Tables',
              amount: Number(formik.values.tables),
              cost: totals.tables,
            },

            {
              item: 'Pinot Grigio',
              amount: Number(formik.values.winePinotGrigio),
              cost: totals.winePinot,
            },
            {
              item: 'Merlot',
              amount: Number(formik.values.wineMerlot),
              cost: totals.wineMerlot,
            },

            {
              item: 'Additional Donation',
              cost: totals.donation,
            },
          ]}
        />
        <Box addMarginBottom>
          <p>
            On pressing submit, your registration form will be submitted to WAG
            and you will be taken to a confirmation page to complete your
            donation. Thank you for your support!
          </p>
        </Box>
        <button
          className="btn btn--large btn--primary mb-2"
          disabled={
            totals.total <= 0 ||
            Object.keys(formik.errors).length > 0 ||
            !canSubmit
          }
          type="submit"
        >
          {canSubmit ? 'Submit' : 'Submitting...'}
        </button>
        <FormLayout.Error>
          {submitErrors.failed()}
          {submitErrors.failed() && <br />}
          {submitErrors.required()}
          {submitErrors.required() && <br />}
          {submitErrors.donation()}
        </FormLayout.Error>
      </form>
    </Section>
  );
};

export default Dogtoberfest;
