import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ErrorLoading from '../../alerts/ErrorLoading';
import Loading from '../../alerts/Loading';
import Section from '../../layout/Section';

import { filterBySchema } from '../../../helpers';
import { GET_NEWSLETTER_BY_ID } from '../../../api/queries';
import { NewsletterType } from '../../../types/commonTypes';
import { Link } from 'react-router-dom';
import FlexItemsContainer from '../../layout/FlexItemsContainer';

interface Params {
  id: string;
}

const newsletterSchema = {
  pdfUrl: 'string',
  title: 'string',
};

export const NewsletterLoader = (): ReactElement => {
  const { id } = useParams<Params>();
  const { loading, error, data } = useQuery(GET_NEWSLETTER_BY_ID, {
    variables: { id },
  });
  const [newsletter, setNewsletter] = useState<NewsletterType>();

  // on query success, sanitize data against schema and set state
  useEffect(() => {
    if (data?.newsletterById) {
      const [n] = filterBySchema([data.newsletterById], newsletterSchema);
      if (n[0]) {
        setNewsletter(n[0] as NewsletterType);
      }
    }
  }, [data]);

  useEffect(() => {
    if (newsletter) {
      window.open(newsletter.pdfUrl, '_blank');
    }
  }, [newsletter]);

  return (
    <Section styles="bg-gray-200 rounded-sm">
      {newsletter && (
        <Section>
          <h2>WAG Newsletter: {newsletter.title}</h2>
          <p>The newsletter should open in a new tab.</p>
          <p>
            If the newsletter hasn't opened you may need to allow pop-ups on
            this page, or click here:
          </p>
          <FlexItemsContainer>
            <a
              className="btn btn--large btn--primary btn--responsive-mx"
              href={newsletter.pdfUrl}
              target="_blank"
              rel="noreferrer"
            >
              Open Newsletter
            </a>
            <Link
              className="btn btn--large btn--secondary btn--responsive-mx"
              to="/articles/newsletters"
            >
              Back to Newsletters
            </Link>
          </FlexItemsContainer>
        </Section>
      )}
      {loading && <Loading />}
      {error && <ErrorLoading />}
    </Section>
  );
};
