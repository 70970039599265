// styles for .article-viewer in index.css

import { useEffect, useState } from 'react';
import edjsParser from 'editorjs-parser';

import { cleanHTMLString } from '../../../helpers';
import { edjsParserConfig } from './editorJsParserConfig';

const parser = new edjsParser({}, edjsParserConfig);

const EditorViewer = (props) => {
  const { data } = props;
  const [htmlParsed, setHtmlParsed] = useState();

  useEffect(() => {
    if (data) {
      // Turn data into HTML and sanitize
      setHtmlParsed(cleanHTMLString(parser.parse(data)));
    }
  }, [data]);

  return (
    <>
      {htmlParsed && (
        <div
          className="article-viewer"
          dangerouslySetInnerHTML={{ __html: htmlParsed }}
        />
      )}
    </>
  );
};

export default EditorViewer;
