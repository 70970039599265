import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { formatDate, parseArticle } from '../../../../helpers';
import { GET_ARTICLE_BY_ID } from '../../../../api/queries';
import { ArticleType } from '../../../../types/commonTypes';

import { EditorViewer } from '../../../dataViews/EditorViewer';
import ErrorLoading from '../../ErrorLoading';
import Loading from '..';
import Section from '../../../layout/Section';

interface Params {
  id: string;
}

interface ArticleProps {
  titleOverride?: string;
  hideDate?: boolean;
  articleId?: string;
}

export const Article = (props: ArticleProps): ReactElement => {
  const { titleOverride, hideDate } = props;
  const { id } = useParams<Params>();
  const { loading, error, data, refetch } = useQuery(GET_ARTICLE_BY_ID, {
    variables: { id: props.articleId ? props.articleId : id },
  });
  const [articleData, setArticleData] = useState<ArticleType>();

  // check if fetch data is good, parse stringified data, then set articleData
  useEffect(() => {
    if (data?.articleById) {
      const parsed = parseArticle(data.articleById);
      if (parsed.valid) {
        setArticleData(parsed.article);
      }
    }
  }, [data]);

  // fetch new article if another article is loaded while Article is still mounted
  useEffect(() => {
    refetch({ id: props.articleId ? props.articleId : id });
  }, [id, refetch, props.articleId]);

  return (
    <Section>
      {articleData && (
        <>
          <div className="sm:mb-12">
            <h2 className="text-3xl mb-2 text-center font-bold">
              {titleOverride ? titleOverride : articleData.title}
            </h2>
            {!hideDate && (
              <p className="text-center">{formatDate(articleData.date)}</p>
            )}
          </div>
          <EditorViewer data={articleData} />
        </>
      )}
      {loading && <Loading />}
      {error && <ErrorLoading />}
    </Section>
  );
};
